import { MutableRefObject, useCallback, useRef, useState } from 'react'
import Modal from 'compass/layout/Modal'
import { useCompassContext } from 'compass-local/CompassProvider/utils'
import { t, Trans } from 'content'
import Typography from 'compass/data/Typography'
import { showToast } from 'utils/toast'
import Divider from 'compass/data/Divider'
import { base, Mount } from './base'
import { Qualified, ThemeProps } from './types'
import { noOpMount } from './internal-utils'
import { button } from './subtypes'

type ConfirmedFileUpload = {
  file: File | string
  uploadId: string
  isLoading: false
}

type FilePickerGhostProps = {
  inputRef: MutableRefObject<HTMLInputElement | null>
  maxFiles?: number
  upload: (value: File) => Promise<string>
  onChoose: (newValues: ConfirmedFileUpload[]) => void
}

function FilePickerGhost({ inputRef, upload, maxFiles = 1, onChoose }: FilePickerGhostProps) {
  return (
    <input
      className="hidden"
      type="file"
      ref={inputRef}
      multiple={maxFiles > 1}
      onChange={async (e) => {
        const files = Array.from(e.target.files ?? [])
        const uploadedFiles = await Promise.all(files.map(upload))
        onChoose(uploadedFiles.map((x, i) => ({ file: files[i], uploadId: x, isLoading: false })))
      }}
    />
  )
}

function useFileActionProps() {
  const ref = useRef<HTMLInputElement | null>(null)
  const { uploadFile = () => Promise.resolve('') } = useCompassContext()
  const [isLoading, setIsLoading] = useState(false)

  const upload = useCallback(
    async (f: File) => {
      setIsLoading(true)
      const uploadId = await uploadFile(f)
      setIsLoading(false)
      return uploadId
    },
    [uploadFile],
  )
  return { ref, upload, isLoading }
}

type FileProps<TCtx extends object | true> = ThemeProps & {
  qualify?: () => Qualified<TCtx & {}>
  maxFiles?: number
  onChoose: (files: ConfirmedFileUpload[]) => void
}

export function file<TCtx extends object | true>(name: string, props: FileProps<TCtx>) {
  const qualify = props.qualify ?? (() => true as TCtx)
  return base(name, {
    ...props,
    qualify,
    useMountCtx: useFileActionProps,
    getMountProps: ({ mountCtx }) => ({
      inputRef: mountCtx.ref,
      onChoose: props.onChoose,
      upload: mountCtx.upload,
      maxFiles: props.maxFiles,
    }),
    Mount: FilePickerGhost,
    isLoading: ({ isLoading }) => isLoading,
    onClick: ({ mountCtx }) => mountCtx.ref.current?.click(),
  })
}

function useNextStatus() {
  return useState(false)
}

type NextProps<TCtx> = ThemeProps & {
  onClick: (ctx: TCtx) => any
  qualify?: () => Qualified<TCtx>
  isLoading?: boolean
  onSuccess?: () => void
}

export function next<TCtx>(name: string, props: NextProps<TCtx>) {
  const qualify = props.qualify ?? (() => true as TCtx)
  return base('', {
    ...props,
    richText: (
      <Typography variant="subtitle">
        <Trans
          tKey="Next: <X>{{ X }}</X>"
          X={<Typography variant="title" />}
          values={{ X: name }}
        />
      </Typography>
    ),
    qualify,
    useMountCtx: useNextStatus,
    getMountProps: () => ({}),
    Mount: noOpMount,
    isLoading: ([isLoading]) => props.isLoading || isLoading,
    onClick: async ({ qualCtx, mountCtx: [, setIsLoading] }) => {
      try {
        setIsLoading(true)
        await Promise.resolve(props.onClick(qualCtx))
        props.onSuccess?.()
      } catch (e: any) {
        // pass
      } finally {
        setIsLoading(false)
      }
    },
  })
}

type Mutation2Props<TRes, TCtx> = ThemeProps & {
  qualify?: () => Qualified<TCtx>
  toast?: (result: TRes, ctx: TCtx) => string
  onSuccess?: (result: TRes, ctx: TCtx) => void
  mutate: (ctx: TCtx) => Promise<TRes>
  confirmation?: false | string
}

type MutationConfirmationMountProps = {
  confirm: null | (() => Promise<void>)
  cancel: () => void
  name: string
  confirmation: false | string
  isLoading: boolean
}

export function MutationConfirmationMount({
  confirm,
  name,
  cancel,
  confirmation,
  isLoading,
}: MutationConfirmationMountProps) {
  // Note: the DAG doesn't work out to be able to use ClosableLayout here
  return (
    <Modal isActive={!!confirm} setInactive={cancel}>
      {confirmation && !!confirm && (
        <div className="vflex gap-5 p-6">
          <Typography variant="drawerheader">{name}</Typography>
          <Typography className="text-th-text-secondary">{confirmation}</Typography>
          <Divider />
          <div className="flex gap-5 items-center justify-end">
            <div className="w-min flex gap-4 items-center">
              {[
                button(t('Cancel'), { theme: 'secondary', onClick: cancel }),
                button(t('Confirm'), { onClick: confirm, isLoading }),
              ].map((x, i) => (
                <Mount key={`${x.name}-${i}`} {...x} />
              ))}
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

function useMutateStatus() {
  return {
    // eslint-disable-next-line
    isLoadingState: useState(false),
    // eslint-disable-next-line
    confirmState: useState<{ confirm: null | (() => Promise<void>) }>({ confirm: null }),
  }
}

export function mutation<TRes, TCtx>(name: string, props: Mutation2Props<TRes, TCtx>) {
  const qualify = props.qualify ?? (() => ({}) as TCtx)
  return base(name, {
    useMountCtx: useMutateStatus,
    getMountProps: ({ mountCtx }) => ({
      confirm: mountCtx.confirmState[0].confirm,
      cancel: () => mountCtx.confirmState[1]({ confirm: null }),
      confirmation: props.confirmation ?? false,
      name,
      isLoading: mountCtx.isLoadingState[0],
    }),
    Mount: MutationConfirmationMount,
    type: 'button',
    isLoading: ({ isLoadingState }) => isLoadingState[0],
    onClick: async ({ qualCtx, mountCtx }) => {
      const handle = async () => {
        try {
          mountCtx.isLoadingState[1](true)
          const res = await props.mutate(qualCtx)
          props.onSuccess?.(res, qualCtx)
          const toast = props.toast?.(res, qualCtx)
          if (toast) {
            showToast({ title: toast })
          }
        } catch (e: any) {
          // pass
        } finally {
          mountCtx.isLoadingState[1](false)
        }
      }

      if (props.confirmation) {
        const confirm = async () => {
          await handle()
          mountCtx.confirmState[1]({ confirm: null })
        }
        mountCtx.confirmState[1]({ confirm })
      } else {
        await handle()
      }
    },
    ...props,
    qualify,
  })
}
