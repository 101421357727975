/* eslint-disable mosaic-js/unnamed-args */
// eslint-disable-next-line
import { useQuery, useQueryClient, UseQueryOptions, QueryKey } from '@tanstack/react-query'
import { ExtractFromList } from '../utils/generator-utils'
import * as R from './resources2'

// eslint-disable-next-line
type MutateOptions = {
  disableAutoInvalidate?: boolean
}
export function useListAdditionalContacts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['AdditionalContactSpec']['list']>>>,
>(...props: Parameters<typeof R.AdditionalContactSpec.listQ<TSelect>>) {
  return useQuery(R.AdditionalContactSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListAdditionalContactsHook() {
  return async (...props: Parameters<typeof R.AdditionalContactSpec.list>) => {
    const res = await R.AdditionalContactSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveAdditionalContact<
  TSelect = Awaited<ReturnType<(typeof R)['AdditionalContactSpec']['retrieve']>>,
>(...props: Parameters<typeof R.AdditionalContactSpec.retrieveQ<TSelect>>) {
  return useQuery(R.AdditionalContactSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveAdditionalContactHook() {
  return async (...props: Parameters<typeof R.AdditionalContactSpec.retrieve>) => {
    const res = await R.AdditionalContactSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateAdditionalContactHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.AdditionalContactSpec.update>) => {
    const res = await R.AdditionalContactSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.AdditionalContactSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyAdditionalContactHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.AdditionalContactSpec.delete>) => {
    const res = await R.AdditionalContactSpec.delete(...props)
    R.AdditionalContactSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateAdditionalContactHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.AdditionalContactSpec.create>) => {
    const res = await R.AdditionalContactSpec.create(...props)
    R.AdditionalContactSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListBusiness<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BusinessSpec']['list']>>>,
>(...props: Parameters<typeof R.BusinessSpec.listQ<TSelect>>) {
  return useQuery(R.BusinessSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBusinessHook() {
  return async (...props: Parameters<typeof R.BusinessSpec.list>) => {
    const res = await R.BusinessSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBusiness<
  TSelect = Awaited<ReturnType<(typeof R)['BusinessSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BusinessSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BusinessSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBusinessHook() {
  return async (...props: Parameters<typeof R.BusinessSpec.retrieve>) => {
    const res = await R.BusinessSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateBusinessHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessSpec.update>) => {
    const res = await R.BusinessSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.BusinessSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateBusinessHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessSpec.create>) => {
    const res = await R.BusinessSpec.create(...props)
    R.BusinessSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListEmployeeCommunicationConfigs<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['EmployeeCommunicationConfigSpec']['list']>>
  >,
>(...props: Parameters<typeof R.EmployeeCommunicationConfigSpec.listQ<TSelect>>) {
  return useQuery(R.EmployeeCommunicationConfigSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEmployeeCommunicationConfigsHook() {
  return async (...props: Parameters<typeof R.EmployeeCommunicationConfigSpec.list>) => {
    const res = await R.EmployeeCommunicationConfigSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEmployeeCommunicationConfig<
  TSelect = Awaited<ReturnType<(typeof R)['EmployeeCommunicationConfigSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EmployeeCommunicationConfigSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EmployeeCommunicationConfigSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEmployeeCommunicationConfigHook() {
  return async (...props: Parameters<typeof R.EmployeeCommunicationConfigSpec.retrieve>) => {
    const res = await R.EmployeeCommunicationConfigSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateEmployeeCommunicationConfigHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EmployeeCommunicationConfigSpec.update>) => {
    const res = await R.EmployeeCommunicationConfigSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.EmployeeCommunicationConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonEmployeeCommunicationConfig<
  TSelect = Awaited<ReturnType<(typeof R)['EmployeeCommunicationConfigSpec']['singleton']>>,
>(...props: Parameters<typeof R.EmployeeCommunicationConfigSpec.singletonQ<TSelect>>) {
  return useQuery(R.EmployeeCommunicationConfigSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListEmployees<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EmployeeSpec']['list']>>>,
>(...props: Parameters<typeof R.EmployeeSpec.listQ<TSelect>>) {
  return useQuery(R.EmployeeSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEmployeesHook() {
  return async (...props: Parameters<typeof R.EmployeeSpec.list>) => {
    const res = await R.EmployeeSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEmployee<
  TSelect = Awaited<ReturnType<(typeof R)['EmployeeSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EmployeeSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EmployeeSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEmployeeHook() {
  return async (...props: Parameters<typeof R.EmployeeSpec.retrieve>) => {
    const res = await R.EmployeeSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateEmployeeHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EmployeeSpec.update>) => {
    const res = await R.EmployeeSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.EmployeeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyEmployeeHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EmployeeSpec.delete>) => {
    const res = await R.EmployeeSpec.delete(...props)
    R.EmployeeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateEmployeeHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EmployeeSpec.create>) => {
    const res = await R.EmployeeSpec.create(...props)
    R.EmployeeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListGCEmails<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['GCEmailSpec']['list']>>>,
>(...props: Parameters<typeof R.GCEmailSpec.listQ<TSelect>>) {
  return useQuery(R.GCEmailSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListGCEmailsHook() {
  return async (...props: Parameters<typeof R.GCEmailSpec.list>) => {
    const res = await R.GCEmailSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveGCEmail<
  TSelect = Awaited<ReturnType<(typeof R)['GCEmailSpec']['retrieve']>>,
>(...props: Parameters<typeof R.GCEmailSpec.retrieveQ<TSelect>>) {
  return useQuery(R.GCEmailSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveGCEmailHook() {
  return async (...props: Parameters<typeof R.GCEmailSpec.retrieve>) => {
    const res = await R.GCEmailSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateGCEmailHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.GCEmailSpec.create>) => {
    const res = await R.GCEmailSpec.create(...props)
    R.GCEmailSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListOutboundInvites<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['OutboundInviteSpec']['list']>>>,
>(...props: Parameters<typeof R.OutboundInviteSpec.listQ<TSelect>>) {
  return useQuery(R.OutboundInviteSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListOutboundInvitesHook() {
  return async (...props: Parameters<typeof R.OutboundInviteSpec.list>) => {
    const res = await R.OutboundInviteSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveOutboundInvite<
  TSelect = Awaited<ReturnType<(typeof R)['OutboundInviteSpec']['retrieve']>>,
>(...props: Parameters<typeof R.OutboundInviteSpec.retrieveQ<TSelect>>) {
  return useQuery(R.OutboundInviteSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveOutboundInviteHook() {
  return async (...props: Parameters<typeof R.OutboundInviteSpec.retrieve>) => {
    const res = await R.OutboundInviteSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateOutboundInviteHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.OutboundInviteSpec.create>) => {
    const res = await R.OutboundInviteSpec.create(...props)
    R.OutboundInviteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListPayeeContacts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['PayeeContactSpec']['list']>>>,
>(...props: Parameters<typeof R.PayeeContactSpec.listQ<TSelect>>) {
  return useQuery(R.PayeeContactSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPayeeContactsHook() {
  return async (...props: Parameters<typeof R.PayeeContactSpec.list>) => {
    const res = await R.PayeeContactSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePayeeContact<
  TSelect = Awaited<ReturnType<(typeof R)['PayeeContactSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PayeeContactSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PayeeContactSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePayeeContactHook() {
  return async (...props: Parameters<typeof R.PayeeContactSpec.retrieve>) => {
    const res = await R.PayeeContactSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdatePayeeContactHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PayeeContactSpec.update>) => {
    const res = await R.PayeeContactSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.PayeeContactSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreatePayeeContactHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PayeeContactSpec.create>) => {
    const res = await R.PayeeContactSpec.create(...props)
    R.PayeeContactSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetPayeeContactArchiveRestrictions<
  TSelect = Awaited<ReturnType<(typeof R)['PayeeContactSpec']['archive_restrictions']>>,
>(...props: Parameters<typeof R.PayeeContactSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.PayeeContactSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useGetPayeeContactArchiveRestrictionsList<
  TSelect = Awaited<ReturnType<(typeof R)['GetPayeeContactArchiveRestrictionsListSpec']['get']>>,
>(...props: Parameters<typeof R.GetPayeeContactArchiveRestrictionsListSpec.getQ<TSelect>>) {
  return useQuery(R.GetPayeeContactArchiveRestrictionsListSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetPayeeContactArchiveRestrictionsListHook() {
  return async (...props: Parameters<typeof R.GetPayeeContactArchiveRestrictionsListSpec.get>) => {
    const res = await R.GetPayeeContactArchiveRestrictionsListSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListPayerContacts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['PayerContactSpec']['list']>>>,
>(...props: Parameters<typeof R.PayerContactSpec.listQ<TSelect>>) {
  return useQuery(R.PayerContactSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPayerContactsHook() {
  return async (...props: Parameters<typeof R.PayerContactSpec.list>) => {
    const res = await R.PayerContactSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePayerContact<
  TSelect = Awaited<ReturnType<(typeof R)['PayerContactSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PayerContactSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PayerContactSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePayerContactHook() {
  return async (...props: Parameters<typeof R.PayerContactSpec.retrieve>) => {
    const res = await R.PayerContactSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdatePayerContactHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PayerContactSpec.update>) => {
    const res = await R.PayerContactSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.PayerContactSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreatePayerContactHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PayerContactSpec.create>) => {
    const res = await R.PayerContactSpec.create(...props)
    R.PayerContactSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetPayerContactArchiveRestrictions<
  TSelect = Awaited<ReturnType<(typeof R)['PayerContactSpec']['archive_restrictions']>>,
>(...props: Parameters<typeof R.PayerContactSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.PayerContactSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useGetPayerContactArchiveRestrictionsList<
  TSelect = Awaited<ReturnType<(typeof R)['GetPayerContactArchiveRestrictionsListSpec']['get']>>,
>(...props: Parameters<typeof R.GetPayerContactArchiveRestrictionsListSpec.getQ<TSelect>>) {
  return useQuery(R.GetPayerContactArchiveRestrictionsListSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetPayerContactArchiveRestrictionsListHook() {
  return async (...props: Parameters<typeof R.GetPayerContactArchiveRestrictionsListSpec.get>) => {
    const res = await R.GetPayerContactArchiveRestrictionsListSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListExternalCommunicationConfigs<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['ExternalCommunicationConfigSpec']['list']>>
  >,
>(...props: Parameters<typeof R.ExternalCommunicationConfigSpec.listQ<TSelect>>) {
  return useQuery(R.ExternalCommunicationConfigSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListExternalCommunicationConfigsHook() {
  return async (...props: Parameters<typeof R.ExternalCommunicationConfigSpec.list>) => {
    const res = await R.ExternalCommunicationConfigSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveExternalCommunicationConfig<
  TSelect = Awaited<ReturnType<(typeof R)['ExternalCommunicationConfigSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ExternalCommunicationConfigSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ExternalCommunicationConfigSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveExternalCommunicationConfigHook() {
  return async (...props: Parameters<typeof R.ExternalCommunicationConfigSpec.retrieve>) => {
    const res = await R.ExternalCommunicationConfigSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateExternalCommunicationConfigHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ExternalCommunicationConfigSpec.update>) => {
    const res = await R.ExternalCommunicationConfigSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ExternalCommunicationConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateExternalCommunicationConfigHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ExternalCommunicationConfigSpec.create>) => {
    const res = await R.ExternalCommunicationConfigSpec.create(...props)
    R.ExternalCommunicationConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonExternalCommunicationConfig<
  TSelect = Awaited<ReturnType<(typeof R)['ExternalCommunicationConfigSpec']['singleton']>>,
>(...props: Parameters<typeof R.ExternalCommunicationConfigSpec.singletonQ<TSelect>>) {
  return useQuery(R.ExternalCommunicationConfigSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListStripeConnectedAccountLinks<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['StripeConnectedAccountLinkSpec']['list']>>
  >,
>(...props: Parameters<typeof R.StripeConnectedAccountLinkSpec.listQ<TSelect>>) {
  return useQuery(R.StripeConnectedAccountLinkSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListStripeConnectedAccountLinksHook() {
  return async (...props: Parameters<typeof R.StripeConnectedAccountLinkSpec.list>) => {
    const res = await R.StripeConnectedAccountLinkSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveStripeConnectedAccountLink<
  TSelect = Awaited<ReturnType<(typeof R)['StripeConnectedAccountLinkSpec']['retrieve']>>,
>(...props: Parameters<typeof R.StripeConnectedAccountLinkSpec.retrieveQ<TSelect>>) {
  return useQuery(R.StripeConnectedAccountLinkSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveStripeConnectedAccountLinkHook() {
  return async (...props: Parameters<typeof R.StripeConnectedAccountLinkSpec.retrieve>) => {
    const res = await R.StripeConnectedAccountLinkSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListBusinessLogoEmbeddedFiles<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['BusinessLogoEmbeddedFileSpec']['list']>>
  >,
>(...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.BusinessLogoEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBusinessLogoEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.list>) => {
    const res = await R.BusinessLogoEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBusinessLogoEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['BusinessLogoEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BusinessLogoEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBusinessLogoEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.retrieve>) => {
    const res = await R.BusinessLogoEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateBusinessLogoEmbeddedFileHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.update>) => {
    const res = await R.BusinessLogoEmbeddedFileSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.BusinessLogoEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyBusinessLogoEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.delete>) => {
    const res = await R.BusinessLogoEmbeddedFileSpec.delete(...props)
    R.BusinessLogoEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateBusinessLogoEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.create>) => {
    const res = await R.BusinessLogoEmbeddedFileSpec.create(...props)
    R.BusinessLogoEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useBusinessLogoEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.download>) => {
    const res = await R.BusinessLogoEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useBusinessLogoEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['BusinessLogoEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.BusinessLogoEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.BusinessLogoEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListChangeOrderEmbeddedFiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ChangeOrderEmbeddedFileSpec']['list']>>>,
>(...props: Parameters<typeof R.ChangeOrderEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.ChangeOrderEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListChangeOrderEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.ChangeOrderEmbeddedFileSpec.list>) => {
    const res = await R.ChangeOrderEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveChangeOrderEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['ChangeOrderEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ChangeOrderEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ChangeOrderEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveChangeOrderEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.ChangeOrderEmbeddedFileSpec.retrieve>) => {
    const res = await R.ChangeOrderEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateChangeOrderEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ChangeOrderEmbeddedFileSpec.create>) => {
    const res = await R.ChangeOrderEmbeddedFileSpec.create(...props)
    R.ChangeOrderEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useChangeOrderEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.ChangeOrderEmbeddedFileSpec.download>) => {
    const res = await R.ChangeOrderEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useChangeOrderEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['ChangeOrderEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.ChangeOrderEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.ChangeOrderEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListChangeOrders<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ChangeOrderSpec']['list']>>>,
>(...props: Parameters<typeof R.ChangeOrderSpec.listQ<TSelect>>) {
  return useQuery(R.ChangeOrderSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListChangeOrdersHook() {
  return async (...props: Parameters<typeof R.ChangeOrderSpec.list>) => {
    const res = await R.ChangeOrderSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveChangeOrder<
  TSelect = Awaited<ReturnType<(typeof R)['ChangeOrderSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ChangeOrderSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ChangeOrderSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveChangeOrderHook() {
  return async (...props: Parameters<typeof R.ChangeOrderSpec.retrieve>) => {
    const res = await R.ChangeOrderSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateChangeOrderHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ChangeOrderSpec.update>) => {
    const res = await R.ChangeOrderSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ChangeOrderSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyChangeOrderHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ChangeOrderSpec.delete>) => {
    const res = await R.ChangeOrderSpec.delete(...props)
    R.ChangeOrderSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateChangeOrderHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ChangeOrderSpec.create>) => {
    const res = await R.ChangeOrderSpec.create(...props)
    R.ChangeOrderSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListClients<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ClientSpec']['list']>>>,
>(...props: Parameters<typeof R.ClientSpec.listQ<TSelect>>) {
  return useQuery(R.ClientSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListClientsHook() {
  return async (...props: Parameters<typeof R.ClientSpec.list>) => {
    const res = await R.ClientSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveClient<
  TSelect = Awaited<ReturnType<(typeof R)['ClientSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ClientSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ClientSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveClientHook() {
  return async (...props: Parameters<typeof R.ClientSpec.retrieve>) => {
    const res = await R.ClientSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListProjectPermissions<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ProjectPermissionSpec']['list']>>>,
>(...props: Parameters<typeof R.ProjectPermissionSpec.listQ<TSelect>>) {
  return useQuery(R.ProjectPermissionSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectPermissionsHook() {
  return async (...props: Parameters<typeof R.ProjectPermissionSpec.list>) => {
    const res = await R.ProjectPermissionSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectPermission<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectPermissionSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectPermissionSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectPermissionSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectPermissionHook() {
  return async (...props: Parameters<typeof R.ProjectPermissionSpec.retrieve>) => {
    const res = await R.ProjectPermissionSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListProjects<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ProjectSpec']['list']>>>,
>(...props: Parameters<typeof R.ProjectSpec.listQ<TSelect>>) {
  return useQuery(R.ProjectSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectsHook() {
  return async (...props: Parameters<typeof R.ProjectSpec.list>) => {
    const res = await R.ProjectSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProject<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectHook() {
  return async (...props: Parameters<typeof R.ProjectSpec.retrieve>) => {
    const res = await R.ProjectSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateProjectHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectSpec.update>) => {
    const res = await R.ProjectSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ProjectSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyProjectHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectSpec.delete>) => {
    const res = await R.ProjectSpec.delete(...props)
    R.ProjectSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateProjectHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectSpec.create>) => {
    const res = await R.ProjectSpec.create(...props)
    R.ProjectSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListRemovalChangeOrderEmbeddedFiles<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['RemovalChangeOrderEmbeddedFileSpec']['list']>>
  >,
>(...props: Parameters<typeof R.RemovalChangeOrderEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.RemovalChangeOrderEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListRemovalChangeOrderEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.RemovalChangeOrderEmbeddedFileSpec.list>) => {
    const res = await R.RemovalChangeOrderEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveRemovalChangeOrderEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['RemovalChangeOrderEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.RemovalChangeOrderEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.RemovalChangeOrderEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveRemovalChangeOrderEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.RemovalChangeOrderEmbeddedFileSpec.retrieve>) => {
    const res = await R.RemovalChangeOrderEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateRemovalChangeOrderEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.RemovalChangeOrderEmbeddedFileSpec.create>) => {
    const res = await R.RemovalChangeOrderEmbeddedFileSpec.create(...props)
    R.RemovalChangeOrderEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRemovalChangeOrderEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.RemovalChangeOrderEmbeddedFileSpec.download>) => {
    const res = await R.RemovalChangeOrderEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useRemovalChangeOrderEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['RemovalChangeOrderEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.RemovalChangeOrderEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.RemovalChangeOrderEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListRemovalChangeOrders<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['RemovalChangeOrderSpec']['list']>>>,
>(...props: Parameters<typeof R.RemovalChangeOrderSpec.listQ<TSelect>>) {
  return useQuery(R.RemovalChangeOrderSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListRemovalChangeOrdersHook() {
  return async (...props: Parameters<typeof R.RemovalChangeOrderSpec.list>) => {
    const res = await R.RemovalChangeOrderSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveRemovalChangeOrder<
  TSelect = Awaited<ReturnType<(typeof R)['RemovalChangeOrderSpec']['retrieve']>>,
>(...props: Parameters<typeof R.RemovalChangeOrderSpec.retrieveQ<TSelect>>) {
  return useQuery(R.RemovalChangeOrderSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveRemovalChangeOrderHook() {
  return async (...props: Parameters<typeof R.RemovalChangeOrderSpec.retrieve>) => {
    const res = await R.RemovalChangeOrderSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateRemovalChangeOrderHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.RemovalChangeOrderSpec.update>) => {
    const res = await R.RemovalChangeOrderSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.RemovalChangeOrderSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyRemovalChangeOrderHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.RemovalChangeOrderSpec.delete>) => {
    const res = await R.RemovalChangeOrderSpec.delete(...props)
    R.RemovalChangeOrderSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useContractItemEditRestrictionViewSet<
  TSelect = Awaited<ReturnType<(typeof R)['ContractItemEditRestrictionViewSetSpec']['get']>>,
>(...props: Parameters<typeof R.ContractItemEditRestrictionViewSetSpec.getQ<TSelect>>) {
  return useQuery(R.ContractItemEditRestrictionViewSetSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useContractItemEditRestrictionViewSetHook() {
  return async (...props: Parameters<typeof R.ContractItemEditRestrictionViewSetSpec.get>) => {
    const res = await R.ContractItemEditRestrictionViewSetSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListContractEmbeddedFile2s<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ContractEmbeddedFile2Spec']['list']>>>,
>(...props: Parameters<typeof R.ContractEmbeddedFile2Spec.listQ<TSelect>>) {
  return useQuery(R.ContractEmbeddedFile2Spec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListContractEmbeddedFile2sHook() {
  return async (...props: Parameters<typeof R.ContractEmbeddedFile2Spec.list>) => {
    const res = await R.ContractEmbeddedFile2Spec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveContractEmbeddedFile2<
  TSelect = Awaited<ReturnType<(typeof R)['ContractEmbeddedFile2Spec']['retrieve']>>,
>(...props: Parameters<typeof R.ContractEmbeddedFile2Spec.retrieveQ<TSelect>>) {
  return useQuery(R.ContractEmbeddedFile2Spec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveContractEmbeddedFile2Hook() {
  return async (...props: Parameters<typeof R.ContractEmbeddedFile2Spec.retrieve>) => {
    const res = await R.ContractEmbeddedFile2Spec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateContractEmbeddedFile2Hook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ContractEmbeddedFile2Spec.update>) => {
    const res = await R.ContractEmbeddedFile2Spec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ContractEmbeddedFile2Spec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyContractEmbeddedFile2Hook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ContractEmbeddedFile2Spec.delete>) => {
    const res = await R.ContractEmbeddedFile2Spec.delete(...props)
    R.ContractEmbeddedFile2Spec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateContractEmbeddedFile2Hook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ContractEmbeddedFile2Spec.create>) => {
    const res = await R.ContractEmbeddedFile2Spec.create(...props)
    R.ContractEmbeddedFile2Spec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useContractEmbeddedFile2DownloadHook() {
  return async (...props: Parameters<typeof R.ContractEmbeddedFile2Spec.download>) => {
    const res = await R.ContractEmbeddedFile2Spec.download(...props)
    return res
  }
}

// ~~~~~~

export function useContractEmbeddedFile2Download<
  TSelect = Awaited<ReturnType<(typeof R)['ContractEmbeddedFile2Spec']['download']>>,
>(...props: Parameters<typeof R.ContractEmbeddedFile2Spec.downloadQ<TSelect>>) {
  return useQuery(R.ContractEmbeddedFile2Spec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListContractItems<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ContractItemSpec']['list']>>>,
>(...props: Parameters<typeof R.ContractItemSpec.listQ<TSelect>>) {
  return useQuery(R.ContractItemSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListContractItemsHook() {
  return async (...props: Parameters<typeof R.ContractItemSpec.list>) => {
    const res = await R.ContractItemSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveContractItem<
  TSelect = Awaited<ReturnType<(typeof R)['ContractItemSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ContractItemSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ContractItemSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveContractItemHook() {
  return async (...props: Parameters<typeof R.ContractItemSpec.retrieve>) => {
    const res = await R.ContractItemSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateContractItemHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ContractItemSpec.update>) => {
    const res = await R.ContractItemSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ContractItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyContractItemHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ContractItemSpec.delete>) => {
    const res = await R.ContractItemSpec.delete(...props)
    R.ContractItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateContractItemHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ContractItemSpec.create>) => {
    const res = await R.ContractItemSpec.create(...props)
    R.ContractItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetContractItemArchiveRestrictions<
  TSelect = Awaited<ReturnType<(typeof R)['ContractItemSpec']['archive_restrictions']>>,
>(...props: Parameters<typeof R.ContractItemSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.ContractItemSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useListContracts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ContractSpec']['list']>>>,
>(...props: Parameters<typeof R.ContractSpec.listQ<TSelect>>) {
  return useQuery(R.ContractSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListContractsHook() {
  return async (...props: Parameters<typeof R.ContractSpec.list>) => {
    const res = await R.ContractSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveContract<
  TSelect = Awaited<ReturnType<(typeof R)['ContractSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ContractSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ContractSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveContractHook() {
  return async (...props: Parameters<typeof R.ContractSpec.retrieve>) => {
    const res = await R.ContractSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateContractHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ContractSpec.update>) => {
    const res = await R.ContractSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ContractSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateContractHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ContractSpec.create>) => {
    const res = await R.ContractSpec.create(...props)
    R.ContractSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetContractArchiveRestrictions<
  TSelect = Awaited<ReturnType<(typeof R)['ContractSpec']['archive_restrictions']>>,
>(...props: Parameters<typeof R.ContractSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.ContractSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useListCostCodes<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CostCodeSpec']['list']>>>,
>(...props: Parameters<typeof R.CostCodeSpec.listQ<TSelect>>) {
  return useQuery(R.CostCodeSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCostCodesHook() {
  return async (...props: Parameters<typeof R.CostCodeSpec.list>) => {
    const res = await R.CostCodeSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCostCode<
  TSelect = Awaited<ReturnType<(typeof R)['CostCodeSpec']['retrieve']>>,
>(...props: Parameters<typeof R.CostCodeSpec.retrieveQ<TSelect>>) {
  return useQuery(R.CostCodeSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCostCodeHook() {
  return async (...props: Parameters<typeof R.CostCodeSpec.retrieve>) => {
    const res = await R.CostCodeSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateCostCodeHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.CostCodeSpec.update>) => {
    const res = await R.CostCodeSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.CostCodeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateCostCodeHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.CostCodeSpec.create>) => {
    const res = await R.CostCodeSpec.create(...props)
    R.CostCodeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListCostTypes<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CostTypeSpec']['list']>>>,
>(...props: Parameters<typeof R.CostTypeSpec.listQ<TSelect>>) {
  return useQuery(R.CostTypeSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCostTypesHook() {
  return async (...props: Parameters<typeof R.CostTypeSpec.list>) => {
    const res = await R.CostTypeSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCostType<
  TSelect = Awaited<ReturnType<(typeof R)['CostTypeSpec']['retrieve']>>,
>(...props: Parameters<typeof R.CostTypeSpec.retrieveQ<TSelect>>) {
  return useQuery(R.CostTypeSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCostTypeHook() {
  return async (...props: Parameters<typeof R.CostTypeSpec.retrieve>) => {
    const res = await R.CostTypeSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateCostTypeHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.CostTypeSpec.update>) => {
    const res = await R.CostTypeSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.CostTypeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateCostTypeHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.CostTypeSpec.create>) => {
    const res = await R.CostTypeSpec.create(...props)
    R.CostTypeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetCostTypeArchiveRestrictions<
  TSelect = Awaited<ReturnType<(typeof R)['CostTypeSpec']['archive_restrictions']>>,
>(...props: Parameters<typeof R.CostTypeSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.CostTypeSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useListGeneralProjectConfigs<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['GeneralProjectConfigSpec']['list']>>>,
>(...props: Parameters<typeof R.GeneralProjectConfigSpec.listQ<TSelect>>) {
  return useQuery(R.GeneralProjectConfigSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListGeneralProjectConfigsHook() {
  return async (...props: Parameters<typeof R.GeneralProjectConfigSpec.list>) => {
    const res = await R.GeneralProjectConfigSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveGeneralProjectConfig<
  TSelect = Awaited<ReturnType<(typeof R)['GeneralProjectConfigSpec']['retrieve']>>,
>(...props: Parameters<typeof R.GeneralProjectConfigSpec.retrieveQ<TSelect>>) {
  return useQuery(R.GeneralProjectConfigSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveGeneralProjectConfigHook() {
  return async (...props: Parameters<typeof R.GeneralProjectConfigSpec.retrieve>) => {
    const res = await R.GeneralProjectConfigSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateGeneralProjectConfigHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.GeneralProjectConfigSpec.update>) => {
    const res = await R.GeneralProjectConfigSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.GeneralProjectConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateGeneralProjectConfigHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.GeneralProjectConfigSpec.create>) => {
    const res = await R.GeneralProjectConfigSpec.create(...props)
    R.GeneralProjectConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonGeneralProjectConfig<
  TSelect = Awaited<ReturnType<(typeof R)['GeneralProjectConfigSpec']['singleton']>>,
>(...props: Parameters<typeof R.GeneralProjectConfigSpec.singletonQ<TSelect>>) {
  return useQuery(R.GeneralProjectConfigSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListPayerGeneralProjectConfigs<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['PayerGeneralProjectConfigSpec']['list']>>
  >,
>(...props: Parameters<typeof R.PayerGeneralProjectConfigSpec.listQ<TSelect>>) {
  return useQuery(R.PayerGeneralProjectConfigSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPayerGeneralProjectConfigsHook() {
  return async (...props: Parameters<typeof R.PayerGeneralProjectConfigSpec.list>) => {
    const res = await R.PayerGeneralProjectConfigSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePayerGeneralProjectConfig<
  TSelect = Awaited<ReturnType<(typeof R)['PayerGeneralProjectConfigSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PayerGeneralProjectConfigSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PayerGeneralProjectConfigSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePayerGeneralProjectConfigHook() {
  return async (...props: Parameters<typeof R.PayerGeneralProjectConfigSpec.retrieve>) => {
    const res = await R.PayerGeneralProjectConfigSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListRetainageReleases<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['RetainageReleaseSpec']['list']>>>,
>(...props: Parameters<typeof R.RetainageReleaseSpec.listQ<TSelect>>) {
  return useQuery(R.RetainageReleaseSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListRetainageReleasesHook() {
  return async (...props: Parameters<typeof R.RetainageReleaseSpec.list>) => {
    const res = await R.RetainageReleaseSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveRetainageRelease<
  TSelect = Awaited<ReturnType<(typeof R)['RetainageReleaseSpec']['retrieve']>>,
>(...props: Parameters<typeof R.RetainageReleaseSpec.retrieveQ<TSelect>>) {
  return useQuery(R.RetainageReleaseSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveRetainageReleaseHook() {
  return async (...props: Parameters<typeof R.RetainageReleaseSpec.retrieve>) => {
    const res = await R.RetainageReleaseSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateRetainageReleaseHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.RetainageReleaseSpec.create>) => {
    const res = await R.RetainageReleaseSpec.create(...props)
    R.RetainageReleaseSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInternalNoteFileLinks<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InternalNoteFileLinkSpec']['list']>>>,
>(...props: Parameters<typeof R.InternalNoteFileLinkSpec.listQ<TSelect>>) {
  return useQuery(R.InternalNoteFileLinkSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInternalNoteFileLinksHook() {
  return async (...props: Parameters<typeof R.InternalNoteFileLinkSpec.list>) => {
    const res = await R.InternalNoteFileLinkSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInternalNoteFileLink<
  TSelect = Awaited<ReturnType<(typeof R)['InternalNoteFileLinkSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InternalNoteFileLinkSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InternalNoteFileLinkSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInternalNoteFileLinkHook() {
  return async (...props: Parameters<typeof R.InternalNoteFileLinkSpec.retrieve>) => {
    const res = await R.InternalNoteFileLinkSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyInternalNoteFileLinkHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InternalNoteFileLinkSpec.delete>) => {
    const res = await R.InternalNoteFileLinkSpec.delete(...props)
    R.InternalNoteFileLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInternalNoteFileLinkHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InternalNoteFileLinkSpec.create>) => {
    const res = await R.InternalNoteFileLinkSpec.create(...props)
    R.InternalNoteFileLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useInternalNoteFileLinkDownloadHook() {
  return async (...props: Parameters<typeof R.InternalNoteFileLinkSpec.download>) => {
    const res = await R.InternalNoteFileLinkSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useInternalNoteFileLinkDownload<
  TSelect = Awaited<ReturnType<(typeof R)['InternalNoteFileLinkSpec']['download']>>,
>(...props: Parameters<typeof R.InternalNoteFileLinkSpec.downloadQ<TSelect>>) {
  return useQuery(R.InternalNoteFileLinkSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListInternalNotes<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InternalNoteSpec']['list']>>>,
>(...props: Parameters<typeof R.InternalNoteSpec.listQ<TSelect>>) {
  return useQuery(R.InternalNoteSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInternalNotesHook() {
  return async (...props: Parameters<typeof R.InternalNoteSpec.list>) => {
    const res = await R.InternalNoteSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInternalNote<
  TSelect = Awaited<ReturnType<(typeof R)['InternalNoteSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InternalNoteSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InternalNoteSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInternalNoteHook() {
  return async (...props: Parameters<typeof R.InternalNoteSpec.retrieve>) => {
    const res = await R.InternalNoteSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInternalNoteHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InternalNoteSpec.update>) => {
    const res = await R.InternalNoteSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InternalNoteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyInternalNoteHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InternalNoteSpec.delete>) => {
    const res = await R.InternalNoteSpec.delete(...props)
    R.InternalNoteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInternalNoteHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InternalNoteSpec.create>) => {
    const res = await R.InternalNoteSpec.create(...props)
    R.InternalNoteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListNoteFileLinks<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['NoteFileLinkSpec']['list']>>>,
>(...props: Parameters<typeof R.NoteFileLinkSpec.listQ<TSelect>>) {
  return useQuery(R.NoteFileLinkSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListNoteFileLinksHook() {
  return async (...props: Parameters<typeof R.NoteFileLinkSpec.list>) => {
    const res = await R.NoteFileLinkSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveNoteFileLink<
  TSelect = Awaited<ReturnType<(typeof R)['NoteFileLinkSpec']['retrieve']>>,
>(...props: Parameters<typeof R.NoteFileLinkSpec.retrieveQ<TSelect>>) {
  return useQuery(R.NoteFileLinkSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveNoteFileLinkHook() {
  return async (...props: Parameters<typeof R.NoteFileLinkSpec.retrieve>) => {
    const res = await R.NoteFileLinkSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateNoteFileLinkHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.NoteFileLinkSpec.create>) => {
    const res = await R.NoteFileLinkSpec.create(...props)
    R.NoteFileLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useNoteFileLinkDownloadHook() {
  return async (...props: Parameters<typeof R.NoteFileLinkSpec.download>) => {
    const res = await R.NoteFileLinkSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useNoteFileLinkDownload<
  TSelect = Awaited<ReturnType<(typeof R)['NoteFileLinkSpec']['download']>>,
>(...props: Parameters<typeof R.NoteFileLinkSpec.downloadQ<TSelect>>) {
  return useQuery(R.NoteFileLinkSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListNoteMentionLinks<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['NoteMentionLinkSpec']['list']>>>,
>(...props: Parameters<typeof R.NoteMentionLinkSpec.listQ<TSelect>>) {
  return useQuery(R.NoteMentionLinkSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListNoteMentionLinksHook() {
  return async (...props: Parameters<typeof R.NoteMentionLinkSpec.list>) => {
    const res = await R.NoteMentionLinkSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveNoteMentionLink<
  TSelect = Awaited<ReturnType<(typeof R)['NoteMentionLinkSpec']['retrieve']>>,
>(...props: Parameters<typeof R.NoteMentionLinkSpec.retrieveQ<TSelect>>) {
  return useQuery(R.NoteMentionLinkSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveNoteMentionLinkHook() {
  return async (...props: Parameters<typeof R.NoteMentionLinkSpec.retrieve>) => {
    const res = await R.NoteMentionLinkSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateNoteMentionLinkHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.NoteMentionLinkSpec.create>) => {
    const res = await R.NoteMentionLinkSpec.create(...props)
    R.NoteMentionLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListNotes<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['NoteSpec']['list']>>>,
>(...props: Parameters<typeof R.NoteSpec.listQ<TSelect>>) {
  return useQuery(R.NoteSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListNotesHook() {
  return async (...props: Parameters<typeof R.NoteSpec.list>) => {
    const res = await R.NoteSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveNote<TSelect = Awaited<ReturnType<(typeof R)['NoteSpec']['retrieve']>>>(
  ...props: Parameters<typeof R.NoteSpec.retrieveQ<TSelect>>
) {
  return useQuery(R.NoteSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveNoteHook() {
  return async (...props: Parameters<typeof R.NoteSpec.retrieve>) => {
    const res = await R.NoteSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyNoteHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.NoteSpec.delete>) => {
    const res = await R.NoteSpec.delete(...props)
    R.NoteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateNoteHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.NoteSpec.create>) => {
    const res = await R.NoteSpec.create(...props)
    R.NoteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListBaseInvoiceEmbeddedFiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BaseInvoiceEmbeddedFileSpec']['list']>>>,
>(...props: Parameters<typeof R.BaseInvoiceEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.BaseInvoiceEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBaseInvoiceEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedFileSpec.list>) => {
    const res = await R.BaseInvoiceEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBaseInvoiceEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['BaseInvoiceEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BaseInvoiceEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BaseInvoiceEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBaseInvoiceEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedFileSpec.retrieve>) => {
    const res = await R.BaseInvoiceEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyBaseInvoiceEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedFileSpec.delete>) => {
    const res = await R.BaseInvoiceEmbeddedFileSpec.delete(...props)
    R.BaseInvoiceEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateBaseInvoiceEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedFileSpec.create>) => {
    const res = await R.BaseInvoiceEmbeddedFileSpec.create(...props)
    R.BaseInvoiceEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useBaseInvoiceEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedFileSpec.download>) => {
    const res = await R.BaseInvoiceEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useBaseInvoiceEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['BaseInvoiceEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.BaseInvoiceEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.BaseInvoiceEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListBaseInvoiceEmbeddedPrimaryFiles<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['BaseInvoiceEmbeddedPrimaryFileSpec']['list']>>
  >,
>(...props: Parameters<typeof R.BaseInvoiceEmbeddedPrimaryFileSpec.listQ<TSelect>>) {
  return useQuery(R.BaseInvoiceEmbeddedPrimaryFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBaseInvoiceEmbeddedPrimaryFilesHook() {
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedPrimaryFileSpec.list>) => {
    const res = await R.BaseInvoiceEmbeddedPrimaryFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBaseInvoiceEmbeddedPrimaryFile<
  TSelect = Awaited<ReturnType<(typeof R)['BaseInvoiceEmbeddedPrimaryFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BaseInvoiceEmbeddedPrimaryFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BaseInvoiceEmbeddedPrimaryFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBaseInvoiceEmbeddedPrimaryFileHook() {
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedPrimaryFileSpec.retrieve>) => {
    const res = await R.BaseInvoiceEmbeddedPrimaryFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyBaseInvoiceEmbeddedPrimaryFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedPrimaryFileSpec.delete>) => {
    const res = await R.BaseInvoiceEmbeddedPrimaryFileSpec.delete(...props)
    R.BaseInvoiceEmbeddedPrimaryFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateBaseInvoiceEmbeddedPrimaryFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedPrimaryFileSpec.create>) => {
    const res = await R.BaseInvoiceEmbeddedPrimaryFileSpec.create(...props)
    R.BaseInvoiceEmbeddedPrimaryFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useBaseInvoiceEmbeddedPrimaryFileDownloadHook() {
  return async (...props: Parameters<typeof R.BaseInvoiceEmbeddedPrimaryFileSpec.download>) => {
    const res = await R.BaseInvoiceEmbeddedPrimaryFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useBaseInvoiceEmbeddedPrimaryFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['BaseInvoiceEmbeddedPrimaryFileSpec']['download']>>,
>(...props: Parameters<typeof R.BaseInvoiceEmbeddedPrimaryFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.BaseInvoiceEmbeddedPrimaryFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillEmbeddedFiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BillEmbeddedFileSpec']['list']>>>,
>(...props: Parameters<typeof R.BillEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.BillEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.BillEmbeddedFileSpec.list>) => {
    const res = await R.BillEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBillEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['BillEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BillEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BillEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBillEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.BillEmbeddedFileSpec.retrieve>) => {
    const res = await R.BillEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyBillEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BillEmbeddedFileSpec.delete>) => {
    const res = await R.BillEmbeddedFileSpec.delete(...props)
    R.BillEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateBillEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BillEmbeddedFileSpec.create>) => {
    const res = await R.BillEmbeddedFileSpec.create(...props)
    R.BillEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useBillEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.BillEmbeddedFileSpec.download>) => {
    const res = await R.BillEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useBillEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['BillEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.BillEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.BillEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillEmbeddedPrimaryFiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BillEmbeddedPrimaryFileSpec']['list']>>>,
>(...props: Parameters<typeof R.BillEmbeddedPrimaryFileSpec.listQ<TSelect>>) {
  return useQuery(R.BillEmbeddedPrimaryFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillEmbeddedPrimaryFilesHook() {
  return async (...props: Parameters<typeof R.BillEmbeddedPrimaryFileSpec.list>) => {
    const res = await R.BillEmbeddedPrimaryFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBillEmbeddedPrimaryFile<
  TSelect = Awaited<ReturnType<(typeof R)['BillEmbeddedPrimaryFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BillEmbeddedPrimaryFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BillEmbeddedPrimaryFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBillEmbeddedPrimaryFileHook() {
  return async (...props: Parameters<typeof R.BillEmbeddedPrimaryFileSpec.retrieve>) => {
    const res = await R.BillEmbeddedPrimaryFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyBillEmbeddedPrimaryFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BillEmbeddedPrimaryFileSpec.delete>) => {
    const res = await R.BillEmbeddedPrimaryFileSpec.delete(...props)
    R.BillEmbeddedPrimaryFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateBillEmbeddedPrimaryFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BillEmbeddedPrimaryFileSpec.create>) => {
    const res = await R.BillEmbeddedPrimaryFileSpec.create(...props)
    R.BillEmbeddedPrimaryFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useBillEmbeddedPrimaryFileDownloadHook() {
  return async (...props: Parameters<typeof R.BillEmbeddedPrimaryFileSpec.download>) => {
    const res = await R.BillEmbeddedPrimaryFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useBillEmbeddedPrimaryFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['BillEmbeddedPrimaryFileSpec']['download']>>,
>(...props: Parameters<typeof R.BillEmbeddedPrimaryFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.BillEmbeddedPrimaryFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListBills<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BillSpec']['list']>>>,
>(...props: Parameters<typeof R.BillSpec.listQ<TSelect>>) {
  return useQuery(R.BillSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillsHook() {
  return async (...props: Parameters<typeof R.BillSpec.list>) => {
    const res = await R.BillSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBill<TSelect = Awaited<ReturnType<(typeof R)['BillSpec']['retrieve']>>>(
  ...props: Parameters<typeof R.BillSpec.retrieveQ<TSelect>>
) {
  return useQuery(R.BillSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBillHook() {
  return async (...props: Parameters<typeof R.BillSpec.retrieve>) => {
    const res = await R.BillSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateBillHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BillSpec.update>) => {
    const res = await R.BillSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.BillSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyBillHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BillSpec.delete>) => {
    const res = await R.BillSpec.delete(...props)
    R.BillSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateBillHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BillSpec.create>) => {
    const res = await R.BillSpec.create(...props)
    R.BillSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useBillPdf<TSelect = Awaited<ReturnType<(typeof R)['BillSpec']['pdf']>>>(
  ...props: Parameters<typeof R.BillSpec.pdfQ<TSelect>>
) {
  return useQuery(R.BillSpec.pdfQ<TSelect>(...props))
}

// ~~~~~~

export function useBillPdfHook() {
  return async (...props: Parameters<typeof R.BillSpec.pdf>) => {
    const res = await R.BillSpec.pdf(...props)
    return res
  }
}

// ~~~~~~

export function useBillZip<TSelect = Awaited<ReturnType<(typeof R)['BillSpec']['zip']>>>(
  ...props: Parameters<typeof R.BillSpec.zipQ<TSelect>>
) {
  return useQuery(R.BillSpec.zipQ<TSelect>(...props))
}

// ~~~~~~

export function useBillZipHook() {
  return async (...props: Parameters<typeof R.BillSpec.zip>) => {
    const res = await R.BillSpec.zip(...props)
    return res
  }
}

// ~~~~~~

export function useListContractProgressSummarys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ContractProgressSummarySpec']['list']>>>,
>(...props: Parameters<typeof R.ContractProgressSummarySpec.listQ<TSelect>>) {
  return useQuery(R.ContractProgressSummarySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListContractProgressSummarysHook() {
  return async (...props: Parameters<typeof R.ContractProgressSummarySpec.list>) => {
    const res = await R.ContractProgressSummarySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveContractProgressSummary<
  TSelect = Awaited<ReturnType<(typeof R)['ContractProgressSummarySpec']['retrieve']>>,
>(...props: Parameters<typeof R.ContractProgressSummarySpec.retrieveQ<TSelect>>) {
  return useQuery(R.ContractProgressSummarySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveContractProgressSummaryHook() {
  return async (...props: Parameters<typeof R.ContractProgressSummarySpec.retrieve>) => {
    const res = await R.ContractProgressSummarySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListExternalPaymentSources<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ExternalPaymentSourceSpec']['list']>>>,
>(...props: Parameters<typeof R.ExternalPaymentSourceSpec.listQ<TSelect>>) {
  return useQuery(R.ExternalPaymentSourceSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListExternalPaymentSourcesHook() {
  return async (...props: Parameters<typeof R.ExternalPaymentSourceSpec.list>) => {
    const res = await R.ExternalPaymentSourceSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveExternalPaymentSource<
  TSelect = Awaited<ReturnType<(typeof R)['ExternalPaymentSourceSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ExternalPaymentSourceSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ExternalPaymentSourceSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveExternalPaymentSourceHook() {
  return async (...props: Parameters<typeof R.ExternalPaymentSourceSpec.retrieve>) => {
    const res = await R.ExternalPaymentSourceSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListFreeFormInvoiceLineItems<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['FreeFormInvoiceLineItemSpec']['list']>>>,
>(...props: Parameters<typeof R.FreeFormInvoiceLineItemSpec.listQ<TSelect>>) {
  return useQuery(R.FreeFormInvoiceLineItemSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListFreeFormInvoiceLineItemsHook() {
  return async (...props: Parameters<typeof R.FreeFormInvoiceLineItemSpec.list>) => {
    const res = await R.FreeFormInvoiceLineItemSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveFreeFormInvoiceLineItem<
  TSelect = Awaited<ReturnType<(typeof R)['FreeFormInvoiceLineItemSpec']['retrieve']>>,
>(...props: Parameters<typeof R.FreeFormInvoiceLineItemSpec.retrieveQ<TSelect>>) {
  return useQuery(R.FreeFormInvoiceLineItemSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveFreeFormInvoiceLineItemHook() {
  return async (...props: Parameters<typeof R.FreeFormInvoiceLineItemSpec.retrieve>) => {
    const res = await R.FreeFormInvoiceLineItemSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateFreeFormInvoiceLineItemHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.FreeFormInvoiceLineItemSpec.update>) => {
    const res = await R.FreeFormInvoiceLineItemSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.FreeFormInvoiceLineItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useListInvoiceActionRequests<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceActionRequestSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceActionRequestSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceActionRequestSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceActionRequestsHook() {
  return async (...props: Parameters<typeof R.InvoiceActionRequestSpec.list>) => {
    const res = await R.InvoiceActionRequestSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceActionRequest<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceActionRequestSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceActionRequestSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceActionRequestSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceActionRequestHook() {
  return async (...props: Parameters<typeof R.InvoiceActionRequestSpec.retrieve>) => {
    const res = await R.InvoiceActionRequestSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceActionRequestHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceActionRequestSpec.create>) => {
    const res = await R.InvoiceActionRequestSpec.create(...props)
    R.InvoiceActionRequestSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInvoiceApprovalActions<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceApprovalActionSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceApprovalActionSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceApprovalActionSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceApprovalActionsHook() {
  return async (...props: Parameters<typeof R.InvoiceApprovalActionSpec.list>) => {
    const res = await R.InvoiceApprovalActionSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceApprovalAction<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceApprovalActionSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceApprovalActionSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceApprovalActionSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceApprovalActionHook() {
  return async (...props: Parameters<typeof R.InvoiceApprovalActionSpec.retrieve>) => {
    const res = await R.InvoiceApprovalActionSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceApprovalActionHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceApprovalActionSpec.create>) => {
    const res = await R.InvoiceApprovalActionSpec.create(...props)
    R.InvoiceApprovalActionSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInvoiceConfigs<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceConfigSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceConfigSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceConfigSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceConfigsHook() {
  return async (...props: Parameters<typeof R.InvoiceConfigSpec.list>) => {
    const res = await R.InvoiceConfigSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceConfig<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceConfigSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceConfigSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceConfigSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceConfigHook() {
  return async (...props: Parameters<typeof R.InvoiceConfigSpec.retrieve>) => {
    const res = await R.InvoiceConfigSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonInvoiceConfig<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceConfigSpec']['singleton']>>,
>(...props: Parameters<typeof R.InvoiceConfigSpec.singletonQ<TSelect>>) {
  return useQuery(R.InvoiceConfigSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceEmbeddedFiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceEmbeddedFileSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.InvoiceEmbeddedFileSpec.list>) => {
    const res = await R.InvoiceEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.InvoiceEmbeddedFileSpec.retrieve>) => {
    const res = await R.InvoiceEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyInvoiceEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceEmbeddedFileSpec.delete>) => {
    const res = await R.InvoiceEmbeddedFileSpec.delete(...props)
    R.InvoiceEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceEmbeddedFileSpec.create>) => {
    const res = await R.InvoiceEmbeddedFileSpec.create(...props)
    R.InvoiceEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useInvoiceEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.InvoiceEmbeddedFileSpec.download>) => {
    const res = await R.InvoiceEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useInvoiceEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.InvoiceEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.InvoiceEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceEmbeddedPrimaryFiles<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['InvoiceEmbeddedPrimaryFileSpec']['list']>>
  >,
>(...props: Parameters<typeof R.InvoiceEmbeddedPrimaryFileSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceEmbeddedPrimaryFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceEmbeddedPrimaryFilesHook() {
  return async (...props: Parameters<typeof R.InvoiceEmbeddedPrimaryFileSpec.list>) => {
    const res = await R.InvoiceEmbeddedPrimaryFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceEmbeddedPrimaryFile<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceEmbeddedPrimaryFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceEmbeddedPrimaryFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceEmbeddedPrimaryFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceEmbeddedPrimaryFileHook() {
  return async (...props: Parameters<typeof R.InvoiceEmbeddedPrimaryFileSpec.retrieve>) => {
    const res = await R.InvoiceEmbeddedPrimaryFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyInvoiceEmbeddedPrimaryFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceEmbeddedPrimaryFileSpec.delete>) => {
    const res = await R.InvoiceEmbeddedPrimaryFileSpec.delete(...props)
    R.InvoiceEmbeddedPrimaryFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceEmbeddedPrimaryFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceEmbeddedPrimaryFileSpec.create>) => {
    const res = await R.InvoiceEmbeddedPrimaryFileSpec.create(...props)
    R.InvoiceEmbeddedPrimaryFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useInvoiceEmbeddedPrimaryFileDownloadHook() {
  return async (...props: Parameters<typeof R.InvoiceEmbeddedPrimaryFileSpec.download>) => {
    const res = await R.InvoiceEmbeddedPrimaryFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useInvoiceEmbeddedPrimaryFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceEmbeddedPrimaryFileSpec']['download']>>,
>(...props: Parameters<typeof R.InvoiceEmbeddedPrimaryFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.InvoiceEmbeddedPrimaryFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceLineItems<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceLineItemSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceLineItemSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceLineItemSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceLineItemsHook() {
  return async (...props: Parameters<typeof R.InvoiceLineItemSpec.list>) => {
    const res = await R.InvoiceLineItemSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceLineItem<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceLineItemSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceLineItemSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceLineItemSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceLineItemHook() {
  return async (...props: Parameters<typeof R.InvoiceLineItemSpec.retrieve>) => {
    const res = await R.InvoiceLineItemSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInvoiceLineItemHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceLineItemSpec.update>) => {
    const res = await R.InvoiceLineItemSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InvoiceLineItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyInvoiceLineItemHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceLineItemSpec.delete>) => {
    const res = await R.InvoiceLineItemSpec.delete(...props)
    R.InvoiceLineItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceLineItemHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceLineItemSpec.create>) => {
    const res = await R.InvoiceLineItemSpec.create(...props)
    R.InvoiceLineItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInvoicePaymentGroups<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoicePaymentGroupSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoicePaymentGroupSpec.listQ<TSelect>>) {
  return useQuery(R.InvoicePaymentGroupSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoicePaymentGroupsHook() {
  return async (...props: Parameters<typeof R.InvoicePaymentGroupSpec.list>) => {
    const res = await R.InvoicePaymentGroupSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoicePaymentGroup<
  TSelect = Awaited<ReturnType<(typeof R)['InvoicePaymentGroupSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoicePaymentGroupSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoicePaymentGroupSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoicePaymentGroupHook() {
  return async (...props: Parameters<typeof R.InvoicePaymentGroupSpec.retrieve>) => {
    const res = await R.InvoicePaymentGroupSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInvoicePaymentGroupHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoicePaymentGroupSpec.update>) => {
    const res = await R.InvoicePaymentGroupSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InvoicePaymentGroupSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateInvoicePaymentGroupHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoicePaymentGroupSpec.create>) => {
    const res = await R.InvoicePaymentGroupSpec.create(...props)
    R.InvoicePaymentGroupSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInvoicePayments<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoicePaymentSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoicePaymentSpec.listQ<TSelect>>) {
  return useQuery(R.InvoicePaymentSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoicePaymentsHook() {
  return async (...props: Parameters<typeof R.InvoicePaymentSpec.list>) => {
    const res = await R.InvoicePaymentSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoicePayment<
  TSelect = Awaited<ReturnType<(typeof R)['InvoicePaymentSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoicePaymentSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoicePaymentSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoicePaymentHook() {
  return async (...props: Parameters<typeof R.InvoicePaymentSpec.retrieve>) => {
    const res = await R.InvoicePaymentSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInvoicePaymentHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoicePaymentSpec.update>) => {
    const res = await R.InvoicePaymentSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InvoicePaymentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyInvoicePaymentHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoicePaymentSpec.delete>) => {
    const res = await R.InvoicePaymentSpec.delete(...props)
    R.InvoicePaymentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInvoicePaymentHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoicePaymentSpec.create>) => {
    const res = await R.InvoicePaymentSpec.create(...props)
    R.InvoicePaymentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetInvoicePaymentArchiveRestrictions<
  TSelect = Awaited<ReturnType<(typeof R)['InvoicePaymentSpec']['archive_restrictions']>>,
>(...props: Parameters<typeof R.InvoicePaymentSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.InvoicePaymentSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useGetInvoicePaymentArchiveRestrictionsList<
  TSelect = Awaited<ReturnType<(typeof R)['GetInvoicePaymentArchiveRestrictionsListSpec']['get']>>,
>(...props: Parameters<typeof R.GetInvoicePaymentArchiveRestrictionsListSpec.getQ<TSelect>>) {
  return useQuery(R.GetInvoicePaymentArchiveRestrictionsListSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetInvoicePaymentArchiveRestrictionsListHook() {
  return async (
    ...props: Parameters<typeof R.GetInvoicePaymentArchiveRestrictionsListSpec.get>
  ) => {
    const res = await R.GetInvoicePaymentArchiveRestrictionsListSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListInvoicePermissions<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoicePermissionSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoicePermissionSpec.listQ<TSelect>>) {
  return useQuery(R.InvoicePermissionSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoicePermissionsHook() {
  return async (...props: Parameters<typeof R.InvoicePermissionSpec.list>) => {
    const res = await R.InvoicePermissionSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoicePermission<
  TSelect = Awaited<ReturnType<(typeof R)['InvoicePermissionSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoicePermissionSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoicePermissionSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoicePermissionHook() {
  return async (...props: Parameters<typeof R.InvoicePermissionSpec.retrieve>) => {
    const res = await R.InvoicePermissionSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListInvoices<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoicesHook() {
  return async (...props: Parameters<typeof R.InvoiceSpec.list>) => {
    const res = await R.InvoiceSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoice<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceHook() {
  return async (...props: Parameters<typeof R.InvoiceSpec.retrieve>) => {
    const res = await R.InvoiceSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInvoiceHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceSpec.update>) => {
    const res = await R.InvoiceSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InvoiceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyInvoiceHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceSpec.delete>) => {
    const res = await R.InvoiceSpec.delete(...props)
    R.InvoiceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceSpec.create>) => {
    const res = await R.InvoiceSpec.create(...props)
    R.InvoiceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useInvoicePdf<TSelect = Awaited<ReturnType<(typeof R)['InvoiceSpec']['pdf']>>>(
  ...props: Parameters<typeof R.InvoiceSpec.pdfQ<TSelect>>
) {
  return useQuery(R.InvoiceSpec.pdfQ<TSelect>(...props))
}

// ~~~~~~

export function useInvoicePdfHook() {
  return async (...props: Parameters<typeof R.InvoiceSpec.pdf>) => {
    const res = await R.InvoiceSpec.pdf(...props)
    return res
  }
}

// ~~~~~~

export function useInvoiceZip<TSelect = Awaited<ReturnType<(typeof R)['InvoiceSpec']['zip']>>>(
  ...props: Parameters<typeof R.InvoiceSpec.zipQ<TSelect>>
) {
  return useQuery(R.InvoiceSpec.zipQ<TSelect>(...props))
}

// ~~~~~~

export function useInvoiceZipHook() {
  return async (...props: Parameters<typeof R.InvoiceSpec.zip>) => {
    const res = await R.InvoiceSpec.zip(...props)
    return res
  }
}

// ~~~~~~

export function useListLienWaiverClaimantSignatures<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['LienWaiverClaimantSignatureSpec']['list']>>
  >,
>(...props: Parameters<typeof R.LienWaiverClaimantSignatureSpec.listQ<TSelect>>) {
  return useQuery(R.LienWaiverClaimantSignatureSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListLienWaiverClaimantSignaturesHook() {
  return async (...props: Parameters<typeof R.LienWaiverClaimantSignatureSpec.list>) => {
    const res = await R.LienWaiverClaimantSignatureSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveLienWaiverClaimantSignature<
  TSelect = Awaited<ReturnType<(typeof R)['LienWaiverClaimantSignatureSpec']['retrieve']>>,
>(...props: Parameters<typeof R.LienWaiverClaimantSignatureSpec.retrieveQ<TSelect>>) {
  return useQuery(R.LienWaiverClaimantSignatureSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveLienWaiverClaimantSignatureHook() {
  return async (...props: Parameters<typeof R.LienWaiverClaimantSignatureSpec.retrieve>) => {
    const res = await R.LienWaiverClaimantSignatureSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListLienWaiverEmbeddedFiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['LienWaiverEmbeddedFileSpec']['list']>>>,
>(...props: Parameters<typeof R.LienWaiverEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.LienWaiverEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListLienWaiverEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedFileSpec.list>) => {
    const res = await R.LienWaiverEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveLienWaiverEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['LienWaiverEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.LienWaiverEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.LienWaiverEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveLienWaiverEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedFileSpec.retrieve>) => {
    const res = await R.LienWaiverEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyLienWaiverEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedFileSpec.delete>) => {
    const res = await R.LienWaiverEmbeddedFileSpec.delete(...props)
    R.LienWaiverEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateLienWaiverEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedFileSpec.create>) => {
    const res = await R.LienWaiverEmbeddedFileSpec.create(...props)
    R.LienWaiverEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useLienWaiverEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedFileSpec.download>) => {
    const res = await R.LienWaiverEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useLienWaiverEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['LienWaiverEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.LienWaiverEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.LienWaiverEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListLienWaiverEmbeddedPrimaryFiles<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['LienWaiverEmbeddedPrimaryFileSpec']['list']>>
  >,
>(...props: Parameters<typeof R.LienWaiverEmbeddedPrimaryFileSpec.listQ<TSelect>>) {
  return useQuery(R.LienWaiverEmbeddedPrimaryFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListLienWaiverEmbeddedPrimaryFilesHook() {
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedPrimaryFileSpec.list>) => {
    const res = await R.LienWaiverEmbeddedPrimaryFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveLienWaiverEmbeddedPrimaryFile<
  TSelect = Awaited<ReturnType<(typeof R)['LienWaiverEmbeddedPrimaryFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.LienWaiverEmbeddedPrimaryFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.LienWaiverEmbeddedPrimaryFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveLienWaiverEmbeddedPrimaryFileHook() {
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedPrimaryFileSpec.retrieve>) => {
    const res = await R.LienWaiverEmbeddedPrimaryFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyLienWaiverEmbeddedPrimaryFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedPrimaryFileSpec.delete>) => {
    const res = await R.LienWaiverEmbeddedPrimaryFileSpec.delete(...props)
    R.LienWaiverEmbeddedPrimaryFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateLienWaiverEmbeddedPrimaryFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedPrimaryFileSpec.create>) => {
    const res = await R.LienWaiverEmbeddedPrimaryFileSpec.create(...props)
    R.LienWaiverEmbeddedPrimaryFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useLienWaiverEmbeddedPrimaryFileDownloadHook() {
  return async (...props: Parameters<typeof R.LienWaiverEmbeddedPrimaryFileSpec.download>) => {
    const res = await R.LienWaiverEmbeddedPrimaryFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useLienWaiverEmbeddedPrimaryFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['LienWaiverEmbeddedPrimaryFileSpec']['download']>>,
>(...props: Parameters<typeof R.LienWaiverEmbeddedPrimaryFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.LienWaiverEmbeddedPrimaryFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListLienWaivers<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['LienWaiverSpec']['list']>>>,
>(...props: Parameters<typeof R.LienWaiverSpec.listQ<TSelect>>) {
  return useQuery(R.LienWaiverSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListLienWaiversHook() {
  return async (...props: Parameters<typeof R.LienWaiverSpec.list>) => {
    const res = await R.LienWaiverSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveLienWaiver<
  TSelect = Awaited<ReturnType<(typeof R)['LienWaiverSpec']['retrieve']>>,
>(...props: Parameters<typeof R.LienWaiverSpec.retrieveQ<TSelect>>) {
  return useQuery(R.LienWaiverSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveLienWaiverHook() {
  return async (...props: Parameters<typeof R.LienWaiverSpec.retrieve>) => {
    const res = await R.LienWaiverSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateLienWaiverHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.LienWaiverSpec.update>) => {
    const res = await R.LienWaiverSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.LienWaiverSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateLienWaiverHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.LienWaiverSpec.create>) => {
    const res = await R.LienWaiverSpec.create(...props)
    R.LienWaiverSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useLienWaiverPdf<
  TSelect = Awaited<ReturnType<(typeof R)['LienWaiverSpec']['pdf']>>,
>(...props: Parameters<typeof R.LienWaiverSpec.pdfQ<TSelect>>) {
  return useQuery(R.LienWaiverSpec.pdfQ<TSelect>(...props))
}

// ~~~~~~

export function useLienWaiverPdfHook() {
  return async (...props: Parameters<typeof R.LienWaiverSpec.pdf>) => {
    const res = await R.LienWaiverSpec.pdf(...props)
    return res
  }
}

// ~~~~~~

export function useListPrimeContractInvoiceEmails<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['PrimeContractInvoiceEmailSpec']['list']>>
  >,
>(...props: Parameters<typeof R.PrimeContractInvoiceEmailSpec.listQ<TSelect>>) {
  return useQuery(R.PrimeContractInvoiceEmailSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPrimeContractInvoiceEmailsHook() {
  return async (...props: Parameters<typeof R.PrimeContractInvoiceEmailSpec.list>) => {
    const res = await R.PrimeContractInvoiceEmailSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePrimeContractInvoiceEmail<
  TSelect = Awaited<ReturnType<(typeof R)['PrimeContractInvoiceEmailSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PrimeContractInvoiceEmailSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PrimeContractInvoiceEmailSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePrimeContractInvoiceEmailHook() {
  return async (...props: Parameters<typeof R.PrimeContractInvoiceEmailSpec.retrieve>) => {
    const res = await R.PrimeContractInvoiceEmailSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListPrimeContractInvoiceEmailViews<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['PrimeContractInvoiceEmailViewSpec']['list']>>
  >,
>(...props: Parameters<typeof R.PrimeContractInvoiceEmailViewSpec.listQ<TSelect>>) {
  return useQuery(R.PrimeContractInvoiceEmailViewSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPrimeContractInvoiceEmailViewsHook() {
  return async (...props: Parameters<typeof R.PrimeContractInvoiceEmailViewSpec.list>) => {
    const res = await R.PrimeContractInvoiceEmailViewSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePrimeContractInvoiceEmailView<
  TSelect = Awaited<ReturnType<(typeof R)['PrimeContractInvoiceEmailViewSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PrimeContractInvoiceEmailViewSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PrimeContractInvoiceEmailViewSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePrimeContractInvoiceEmailViewHook() {
  return async (...props: Parameters<typeof R.PrimeContractInvoiceEmailViewSpec.retrieve>) => {
    const res = await R.PrimeContractInvoiceEmailViewSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListPrimeContractInvoicePaymentPageVisits<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['PrimeContractInvoicePaymentPageVisitSpec']['list']>>
  >,
>(...props: Parameters<typeof R.PrimeContractInvoicePaymentPageVisitSpec.listQ<TSelect>>) {
  return useQuery(R.PrimeContractInvoicePaymentPageVisitSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPrimeContractInvoicePaymentPageVisitsHook() {
  return async (...props: Parameters<typeof R.PrimeContractInvoicePaymentPageVisitSpec.list>) => {
    const res = await R.PrimeContractInvoicePaymentPageVisitSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePrimeContractInvoicePaymentPageVisit<
  TSelect = Awaited<ReturnType<(typeof R)['PrimeContractInvoicePaymentPageVisitSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PrimeContractInvoicePaymentPageVisitSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PrimeContractInvoicePaymentPageVisitSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePrimeContractInvoicePaymentPageVisitHook() {
  return async (
    ...props: Parameters<typeof R.PrimeContractInvoicePaymentPageVisitSpec.retrieve>
  ) => {
    const res = await R.PrimeContractInvoicePaymentPageVisitSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreatePrimeContractInvoicePaymentPageVisitHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PrimeContractInvoicePaymentPageVisitSpec.create>) => {
    const res = await R.PrimeContractInvoicePaymentPageVisitSpec.create(...props)
    R.PrimeContractInvoicePaymentPageVisitSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListProjectExpensePermissions<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['ProjectExpensePermissionSpec']['list']>>
  >,
>(...props: Parameters<typeof R.ProjectExpensePermissionSpec.listQ<TSelect>>) {
  return useQuery(R.ProjectExpensePermissionSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectExpensePermissionsHook() {
  return async (...props: Parameters<typeof R.ProjectExpensePermissionSpec.list>) => {
    const res = await R.ProjectExpensePermissionSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectExpensePermission<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectExpensePermissionSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectExpensePermissionSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectExpensePermissionSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectExpensePermissionHook() {
  return async (...props: Parameters<typeof R.ProjectExpensePermissionSpec.retrieve>) => {
    const res = await R.ProjectExpensePermissionSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListProjectExpenseReceiptEmbeddedFiles<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['ProjectExpenseReceiptEmbeddedFileSpec']['list']>>
  >,
>(...props: Parameters<typeof R.ProjectExpenseReceiptEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.ProjectExpenseReceiptEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectExpenseReceiptEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.ProjectExpenseReceiptEmbeddedFileSpec.list>) => {
    const res = await R.ProjectExpenseReceiptEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectExpenseReceiptEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectExpenseReceiptEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectExpenseReceiptEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectExpenseReceiptEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectExpenseReceiptEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.ProjectExpenseReceiptEmbeddedFileSpec.retrieve>) => {
    const res = await R.ProjectExpenseReceiptEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyProjectExpenseReceiptEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectExpenseReceiptEmbeddedFileSpec.delete>) => {
    const res = await R.ProjectExpenseReceiptEmbeddedFileSpec.delete(...props)
    R.ProjectExpenseReceiptEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateProjectExpenseReceiptEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectExpenseReceiptEmbeddedFileSpec.create>) => {
    const res = await R.ProjectExpenseReceiptEmbeddedFileSpec.create(...props)
    R.ProjectExpenseReceiptEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useProjectExpenseReceiptEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.ProjectExpenseReceiptEmbeddedFileSpec.download>) => {
    const res = await R.ProjectExpenseReceiptEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useProjectExpenseReceiptEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectExpenseReceiptEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.ProjectExpenseReceiptEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.ProjectExpenseReceiptEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectExpenses<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ProjectExpenseSpec']['list']>>>,
>(...props: Parameters<typeof R.ProjectExpenseSpec.listQ<TSelect>>) {
  return useQuery(R.ProjectExpenseSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectExpensesHook() {
  return async (...props: Parameters<typeof R.ProjectExpenseSpec.list>) => {
    const res = await R.ProjectExpenseSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectExpense<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectExpenseSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectExpenseSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectExpenseSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectExpenseHook() {
  return async (...props: Parameters<typeof R.ProjectExpenseSpec.retrieve>) => {
    const res = await R.ProjectExpenseSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateProjectExpenseHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectExpenseSpec.update>) => {
    const res = await R.ProjectExpenseSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ProjectExpenseSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateProjectExpenseHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectExpenseSpec.create>) => {
    const res = await R.ProjectExpenseSpec.create(...props)
    R.ProjectExpenseSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListProjectProgressSummaryV2s<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['ProjectProgressSummaryV2Spec']['list']>>
  >,
>(...props: Parameters<typeof R.ProjectProgressSummaryV2Spec.listQ<TSelect>>) {
  return useQuery(R.ProjectProgressSummaryV2Spec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectProgressSummaryV2sHook() {
  return async (...props: Parameters<typeof R.ProjectProgressSummaryV2Spec.list>) => {
    const res = await R.ProjectProgressSummaryV2Spec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectProgressSummaryV2<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectProgressSummaryV2Spec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectProgressSummaryV2Spec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectProgressSummaryV2Spec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectProgressSummaryV2Hook() {
  return async (...props: Parameters<typeof R.ProjectProgressSummaryV2Spec.retrieve>) => {
    const res = await R.ProjectProgressSummaryV2Spec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListScheduleOfValuesItemProgressSummarys<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['ScheduleOfValuesItemProgressSummarySpec']['list']>>
  >,
>(...props: Parameters<typeof R.ScheduleOfValuesItemProgressSummarySpec.listQ<TSelect>>) {
  return useQuery(R.ScheduleOfValuesItemProgressSummarySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListScheduleOfValuesItemProgressSummarysHook() {
  return async (...props: Parameters<typeof R.ScheduleOfValuesItemProgressSummarySpec.list>) => {
    const res = await R.ScheduleOfValuesItemProgressSummarySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveScheduleOfValuesItemProgressSummary<
  TSelect = Awaited<ReturnType<(typeof R)['ScheduleOfValuesItemProgressSummarySpec']['retrieve']>>,
>(...props: Parameters<typeof R.ScheduleOfValuesItemProgressSummarySpec.retrieveQ<TSelect>>) {
  return useQuery(R.ScheduleOfValuesItemProgressSummarySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveScheduleOfValuesItemProgressSummaryHook() {
  return async (
    ...props: Parameters<typeof R.ScheduleOfValuesItemProgressSummarySpec.retrieve>
  ) => {
    const res = await R.ScheduleOfValuesItemProgressSummarySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useGetExpenseMetrics<
  TSelect = Awaited<ReturnType<(typeof R)['GetExpenseMetricsSpec']['get']>>,
>(...props: Parameters<typeof R.GetExpenseMetricsSpec.getQ<TSelect>>) {
  return useQuery(R.GetExpenseMetricsSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetExpenseMetricsHook() {
  return async (...props: Parameters<typeof R.GetExpenseMetricsSpec.get>) => {
    const res = await R.GetExpenseMetricsSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetInvoiceApprovalWorkflowRequirements<
  TSelect = Awaited<ReturnType<(typeof R)['GetInvoiceApprovalWorkflowRequirementsSpec']['get']>>,
>(...props: Parameters<typeof R.GetInvoiceApprovalWorkflowRequirementsSpec.getQ<TSelect>>) {
  return useQuery(R.GetInvoiceApprovalWorkflowRequirementsSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetInvoiceApprovalWorkflowRequirementsHook() {
  return async (...props: Parameters<typeof R.GetInvoiceApprovalWorkflowRequirementsSpec.get>) => {
    const res = await R.GetInvoiceApprovalWorkflowRequirementsSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetInvoiceCardFee<
  TSelect = Awaited<ReturnType<(typeof R)['GetInvoiceCardFeeSpec']['get']>>,
>(...props: Parameters<typeof R.GetInvoiceCardFeeSpec.getQ<TSelect>>) {
  return useQuery(R.GetInvoiceCardFeeSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetInvoiceCardFeeHook() {
  return async (...props: Parameters<typeof R.GetInvoiceCardFeeSpec.get>) => {
    const res = await R.GetInvoiceCardFeeSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetInvoiceFile<
  TSelect = Awaited<ReturnType<(typeof R)['GetInvoiceFileSpec']['get']>>,
>(...props: Parameters<typeof R.GetInvoiceFileSpec.getQ<TSelect>>) {
  return useQuery(R.GetInvoiceFileSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetInvoiceFileHook() {
  return async (...props: Parameters<typeof R.GetInvoiceFileSpec.get>) => {
    const res = await R.GetInvoiceFileSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetInvoicePaymentStartDate<
  TSelect = Awaited<ReturnType<(typeof R)['GetInvoicePaymentStartDateSpec']['get']>>,
>(...props: Parameters<typeof R.GetInvoicePaymentStartDateSpec.getQ<TSelect>>) {
  return useQuery(R.GetInvoicePaymentStartDateSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetInvoicePaymentStartDateHook() {
  return async (...props: Parameters<typeof R.GetInvoicePaymentStartDateSpec.get>) => {
    const res = await R.GetInvoicePaymentStartDateSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetLienWaiverPaymentBlockers<
  TSelect = Awaited<ReturnType<(typeof R)['GetLienWaiverPaymentBlockersSpec']['get']>>,
>(...props: Parameters<typeof R.GetLienWaiverPaymentBlockersSpec.getQ<TSelect>>) {
  return useQuery(R.GetLienWaiverPaymentBlockersSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetLienWaiverPaymentBlockersHook() {
  return async (...props: Parameters<typeof R.GetLienWaiverPaymentBlockersSpec.get>) => {
    const res = await R.GetLienWaiverPaymentBlockersSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetLienWaiverPdfPreviewV2<
  TSelect = Awaited<ReturnType<(typeof R)['GetLienWaiverPdfPreviewV2Spec']['get']>>,
>(...props: Parameters<typeof R.GetLienWaiverPdfPreviewV2Spec.getQ<TSelect>>) {
  return useQuery(R.GetLienWaiverPdfPreviewV2Spec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetLienWaiverPdfPreviewV2Hook() {
  return async (...props: Parameters<typeof R.GetLienWaiverPdfPreviewV2Spec.get>) => {
    const res = await R.GetLienWaiverPdfPreviewV2Spec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetNextInvoiceNumber<
  TSelect = Awaited<ReturnType<(typeof R)['GetNextInvoiceNumberSpec']['get']>>,
>(...props: Parameters<typeof R.GetNextInvoiceNumberSpec.getQ<TSelect>>) {
  return useQuery(R.GetNextInvoiceNumberSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetNextInvoiceNumberHook() {
  return async (...props: Parameters<typeof R.GetNextInvoiceNumberSpec.get>) => {
    const res = await R.GetNextInvoiceNumberSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetRetainageValues<
  TSelect = Awaited<ReturnType<(typeof R)['GetRetainageValuesSpec']['get']>>,
>(...props: Parameters<typeof R.GetRetainageValuesSpec.getQ<TSelect>>) {
  return useQuery(R.GetRetainageValuesSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetRetainageValuesHook() {
  return async (...props: Parameters<typeof R.GetRetainageValuesSpec.get>) => {
    const res = await R.GetRetainageValuesSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function usePredictUncategorizedExpenseDetails<
  TSelect = Awaited<ReturnType<(typeof R)['PredictUncategorizedExpenseDetailsSpec']['get']>>,
>(...props: Parameters<typeof R.PredictUncategorizedExpenseDetailsSpec.getQ<TSelect>>) {
  return useQuery(R.PredictUncategorizedExpenseDetailsSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function usePredictUncategorizedExpenseDetailsHook() {
  return async (...props: Parameters<typeof R.PredictUncategorizedExpenseDetailsSpec.get>) => {
    const res = await R.PredictUncategorizedExpenseDetailsSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListInvoiceApprovalRules<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceApprovalRuleSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceApprovalRuleSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceApprovalRuleSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceApprovalRulesHook() {
  return async (...props: Parameters<typeof R.InvoiceApprovalRuleSpec.list>) => {
    const res = await R.InvoiceApprovalRuleSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceApprovalRule<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceApprovalRuleSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceApprovalRuleSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceApprovalRuleSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceApprovalRuleHook() {
  return async (...props: Parameters<typeof R.InvoiceApprovalRuleSpec.retrieve>) => {
    const res = await R.InvoiceApprovalRuleSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInvoiceApprovalRuleHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceApprovalRuleSpec.update>) => {
    const res = await R.InvoiceApprovalRuleSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InvoiceApprovalRuleSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyInvoiceApprovalRuleHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceApprovalRuleSpec.delete>) => {
    const res = await R.InvoiceApprovalRuleSpec.delete(...props)
    R.InvoiceApprovalRuleSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceApprovalRuleHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceApprovalRuleSpec.create>) => {
    const res = await R.InvoiceApprovalRuleSpec.create(...props)
    R.InvoiceApprovalRuleSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInvoiceIntentEmbeddedFiles<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['InvoiceIntentEmbeddedFileSpec']['list']>>
  >,
>(...props: Parameters<typeof R.InvoiceIntentEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceIntentEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceIntentEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.InvoiceIntentEmbeddedFileSpec.list>) => {
    const res = await R.InvoiceIntentEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceIntentEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceIntentEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceIntentEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceIntentEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceIntentEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.InvoiceIntentEmbeddedFileSpec.retrieve>) => {
    const res = await R.InvoiceIntentEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInvoiceIntentEmbeddedFileHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceIntentEmbeddedFileSpec.update>) => {
    const res = await R.InvoiceIntentEmbeddedFileSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InvoiceIntentEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceIntentEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceIntentEmbeddedFileSpec.create>) => {
    const res = await R.InvoiceIntentEmbeddedFileSpec.create(...props)
    R.InvoiceIntentEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useInvoiceIntentEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.InvoiceIntentEmbeddedFileSpec.download>) => {
    const res = await R.InvoiceIntentEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useInvoiceIntentEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceIntentEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.InvoiceIntentEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.InvoiceIntentEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceIntents<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceIntentSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceIntentSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceIntentSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceIntentsHook() {
  return async (...props: Parameters<typeof R.InvoiceIntentSpec.list>) => {
    const res = await R.InvoiceIntentSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceIntent<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceIntentSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceIntentSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceIntentSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceIntentHook() {
  return async (...props: Parameters<typeof R.InvoiceIntentSpec.retrieve>) => {
    const res = await R.InvoiceIntentSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInvoiceIntentHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceIntentSpec.update>) => {
    const res = await R.InvoiceIntentSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InvoiceIntentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyInvoiceIntentHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceIntentSpec.delete>) => {
    const res = await R.InvoiceIntentSpec.delete(...props)
    R.InvoiceIntentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceIntentHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceIntentSpec.create>) => {
    const res = await R.InvoiceIntentSpec.create(...props)
    R.InvoiceIntentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInvoiceLineItemMarkupLinks<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['InvoiceLineItemMarkupLinkSpec']['list']>>
  >,
>(...props: Parameters<typeof R.InvoiceLineItemMarkupLinkSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceLineItemMarkupLinkSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceLineItemMarkupLinksHook() {
  return async (...props: Parameters<typeof R.InvoiceLineItemMarkupLinkSpec.list>) => {
    const res = await R.InvoiceLineItemMarkupLinkSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceLineItemMarkupLink<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceLineItemMarkupLinkSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceLineItemMarkupLinkSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceLineItemMarkupLinkSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceLineItemMarkupLinkHook() {
  return async (...props: Parameters<typeof R.InvoiceLineItemMarkupLinkSpec.retrieve>) => {
    const res = await R.InvoiceLineItemMarkupLinkSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateInvoiceLineItemMarkupLinkHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceLineItemMarkupLinkSpec.update>) => {
    const res = await R.InvoiceLineItemMarkupLinkSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.InvoiceLineItemMarkupLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyInvoiceLineItemMarkupLinkHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceLineItemMarkupLinkSpec.delete>) => {
    const res = await R.InvoiceLineItemMarkupLinkSpec.delete(...props)
    R.InvoiceLineItemMarkupLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateInvoiceLineItemMarkupLinkHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InvoiceLineItemMarkupLinkSpec.create>) => {
    const res = await R.InvoiceLineItemMarkupLinkSpec.create(...props)
    R.InvoiceLineItemMarkupLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInvoicePrefillLineItems<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoicePrefillLineItemSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoicePrefillLineItemSpec.listQ<TSelect>>) {
  return useQuery(R.InvoicePrefillLineItemSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoicePrefillLineItemsHook() {
  return async (...props: Parameters<typeof R.InvoicePrefillLineItemSpec.list>) => {
    const res = await R.InvoicePrefillLineItemSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoicePrefillLineItem<
  TSelect = Awaited<ReturnType<(typeof R)['InvoicePrefillLineItemSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoicePrefillLineItemSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoicePrefillLineItemSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoicePrefillLineItemHook() {
  return async (...props: Parameters<typeof R.InvoicePrefillLineItemSpec.retrieve>) => {
    const res = await R.InvoicePrefillLineItemSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListInvoicePrefills<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoicePrefillSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoicePrefillSpec.listQ<TSelect>>) {
  return useQuery(R.InvoicePrefillSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoicePrefillsHook() {
  return async (...props: Parameters<typeof R.InvoicePrefillSpec.list>) => {
    const res = await R.InvoicePrefillSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoicePrefill<
  TSelect = Awaited<ReturnType<(typeof R)['InvoicePrefillSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoicePrefillSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoicePrefillSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoicePrefillHook() {
  return async (...props: Parameters<typeof R.InvoicePrefillSpec.retrieve>) => {
    const res = await R.InvoicePrefillSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListPaymentSources<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['PaymentSourceSpec']['list']>>>,
>(...props: Parameters<typeof R.PaymentSourceSpec.listQ<TSelect>>) {
  return useQuery(R.PaymentSourceSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPaymentSourcesHook() {
  return async (...props: Parameters<typeof R.PaymentSourceSpec.list>) => {
    const res = await R.PaymentSourceSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePaymentSource<
  TSelect = Awaited<ReturnType<(typeof R)['PaymentSourceSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PaymentSourceSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PaymentSourceSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePaymentSourceHook() {
  return async (...props: Parameters<typeof R.PaymentSourceSpec.retrieve>) => {
    const res = await R.PaymentSourceSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdatePaymentSourceHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PaymentSourceSpec.update>) => {
    const res = await R.PaymentSourceSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.PaymentSourceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreatePaymentSourceHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PaymentSourceSpec.create>) => {
    const res = await R.PaymentSourceSpec.create(...props)
    R.PaymentSourceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListTimeEntrys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['TimeEntrySpec']['list']>>>,
>(...props: Parameters<typeof R.TimeEntrySpec.listQ<TSelect>>) {
  return useQuery(R.TimeEntrySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListTimeEntrysHook() {
  return async (...props: Parameters<typeof R.TimeEntrySpec.list>) => {
    const res = await R.TimeEntrySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveTimeEntry<
  TSelect = Awaited<ReturnType<(typeof R)['TimeEntrySpec']['retrieve']>>,
>(...props: Parameters<typeof R.TimeEntrySpec.retrieveQ<TSelect>>) {
  return useQuery(R.TimeEntrySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveTimeEntryHook() {
  return async (...props: Parameters<typeof R.TimeEntrySpec.retrieve>) => {
    const res = await R.TimeEntrySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateTimeEntryHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.TimeEntrySpec.update>) => {
    const res = await R.TimeEntrySpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.TimeEntrySpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateTimeEntryHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.TimeEntrySpec.create>) => {
    const res = await R.TimeEntrySpec.create(...props)
    R.TimeEntrySpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListVendorBillingConfigs<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['VendorBillingConfigSpec']['list']>>>,
>(...props: Parameters<typeof R.VendorBillingConfigSpec.listQ<TSelect>>) {
  return useQuery(R.VendorBillingConfigSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListVendorBillingConfigsHook() {
  return async (...props: Parameters<typeof R.VendorBillingConfigSpec.list>) => {
    const res = await R.VendorBillingConfigSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveVendorBillingConfig<
  TSelect = Awaited<ReturnType<(typeof R)['VendorBillingConfigSpec']['retrieve']>>,
>(...props: Parameters<typeof R.VendorBillingConfigSpec.retrieveQ<TSelect>>) {
  return useQuery(R.VendorBillingConfigSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveVendorBillingConfigHook() {
  return async (...props: Parameters<typeof R.VendorBillingConfigSpec.retrieve>) => {
    const res = await R.VendorBillingConfigSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateVendorBillingConfigHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.VendorBillingConfigSpec.update>) => {
    const res = await R.VendorBillingConfigSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.VendorBillingConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateVendorBillingConfigHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.VendorBillingConfigSpec.create>) => {
    const res = await R.VendorBillingConfigSpec.create(...props)
    R.VendorBillingConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListWorkCompletedSources<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['WorkCompletedSourceSpec']['list']>>>,
>(...props: Parameters<typeof R.WorkCompletedSourceSpec.listQ<TSelect>>) {
  return useQuery(R.WorkCompletedSourceSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListWorkCompletedSourcesHook() {
  return async (...props: Parameters<typeof R.WorkCompletedSourceSpec.list>) => {
    const res = await R.WorkCompletedSourceSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveWorkCompletedSource<
  TSelect = Awaited<ReturnType<(typeof R)['WorkCompletedSourceSpec']['retrieve']>>,
>(...props: Parameters<typeof R.WorkCompletedSourceSpec.retrieveQ<TSelect>>) {
  return useQuery(R.WorkCompletedSourceSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveWorkCompletedSourceHook() {
  return async (...props: Parameters<typeof R.WorkCompletedSourceSpec.retrieve>) => {
    const res = await R.WorkCompletedSourceSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateWorkCompletedSourceHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.WorkCompletedSourceSpec.update>) => {
    const res = await R.WorkCompletedSourceSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.WorkCompletedSourceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyWorkCompletedSourceHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.WorkCompletedSourceSpec.delete>) => {
    const res = await R.WorkCompletedSourceSpec.delete(...props)
    R.WorkCompletedSourceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateWorkCompletedSourceHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.WorkCompletedSourceSpec.create>) => {
    const res = await R.WorkCompletedSourceSpec.create(...props)
    R.WorkCompletedSourceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListDummys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['DummysSpec']['list']>>>,
>(...props: Parameters<typeof R.DummysSpec.listQ<TSelect>>) {
  return useQuery(R.DummysSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListDummysHook() {
  return async (...props: Parameters<typeof R.DummysSpec.list>) => {
    const res = await R.DummysSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useListEmailVerificationAttempts<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['EmailVerificationAttemptSpec']['list']>>
  >,
>(...props: Parameters<typeof R.EmailVerificationAttemptSpec.listQ<TSelect>>) {
  return useQuery(R.EmailVerificationAttemptSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEmailVerificationAttemptsHook() {
  return async (...props: Parameters<typeof R.EmailVerificationAttemptSpec.list>) => {
    const res = await R.EmailVerificationAttemptSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEmailVerificationAttempt<
  TSelect = Awaited<ReturnType<(typeof R)['EmailVerificationAttemptSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EmailVerificationAttemptSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EmailVerificationAttemptSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEmailVerificationAttemptHook() {
  return async (...props: Parameters<typeof R.EmailVerificationAttemptSpec.retrieve>) => {
    const res = await R.EmailVerificationAttemptSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateEmailVerificationAttemptHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EmailVerificationAttemptSpec.create>) => {
    const res = await R.EmailVerificationAttemptSpec.create(...props)
    R.EmailVerificationAttemptSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListEmailVerifications<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EmailVerificationSpec']['list']>>>,
>(...props: Parameters<typeof R.EmailVerificationSpec.listQ<TSelect>>) {
  return useQuery(R.EmailVerificationSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEmailVerificationsHook() {
  return async (...props: Parameters<typeof R.EmailVerificationSpec.list>) => {
    const res = await R.EmailVerificationSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEmailVerification<
  TSelect = Awaited<ReturnType<(typeof R)['EmailVerificationSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EmailVerificationSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EmailVerificationSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEmailVerificationHook() {
  return async (...props: Parameters<typeof R.EmailVerificationSpec.retrieve>) => {
    const res = await R.EmailVerificationSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateEmailVerificationHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EmailVerificationSpec.create>) => {
    const res = await R.EmailVerificationSpec.create(...props)
    R.EmailVerificationSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListUsers<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['UserSpec']['list']>>>,
>(...props: Parameters<typeof R.UserSpec.listQ<TSelect>>) {
  return useQuery(R.UserSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListUsersHook() {
  return async (...props: Parameters<typeof R.UserSpec.list>) => {
    const res = await R.UserSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveUser<TSelect = Awaited<ReturnType<(typeof R)['UserSpec']['retrieve']>>>(
  ...props: Parameters<typeof R.UserSpec.retrieveQ<TSelect>>
) {
  return useQuery(R.UserSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveUserHook() {
  return async (...props: Parameters<typeof R.UserSpec.retrieve>) => {
    const res = await R.UserSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateUserHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.UserSpec.update>) => {
    const res = await R.UserSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.UserSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateUserHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.UserSpec.create>) => {
    const res = await R.UserSpec.create(...props)
    R.UserSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListCards<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CardSpec']['list']>>>,
>(...props: Parameters<typeof R.CardSpec.listQ<TSelect>>) {
  return useQuery(R.CardSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCardsHook() {
  return async (...props: Parameters<typeof R.CardSpec.list>) => {
    const res = await R.CardSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCard<TSelect = Awaited<ReturnType<(typeof R)['CardSpec']['retrieve']>>>(
  ...props: Parameters<typeof R.CardSpec.retrieveQ<TSelect>>
) {
  return useQuery(R.CardSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCardHook() {
  return async (...props: Parameters<typeof R.CardSpec.retrieve>) => {
    const res = await R.CardSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListMicrodepositRequirements<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['MicrodepositRequirementSpec']['list']>>>,
>(...props: Parameters<typeof R.MicrodepositRequirementSpec.listQ<TSelect>>) {
  return useQuery(R.MicrodepositRequirementSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListMicrodepositRequirementsHook() {
  return async (...props: Parameters<typeof R.MicrodepositRequirementSpec.list>) => {
    const res = await R.MicrodepositRequirementSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveMicrodepositRequirement<
  TSelect = Awaited<ReturnType<(typeof R)['MicrodepositRequirementSpec']['retrieve']>>,
>(...props: Parameters<typeof R.MicrodepositRequirementSpec.retrieveQ<TSelect>>) {
  return useQuery(R.MicrodepositRequirementSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveMicrodepositRequirementHook() {
  return async (...props: Parameters<typeof R.MicrodepositRequirementSpec.retrieve>) => {
    const res = await R.MicrodepositRequirementSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListTransactionAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['TransactionAccountSpec']['list']>>>,
>(...props: Parameters<typeof R.TransactionAccountSpec.listQ<TSelect>>) {
  return useQuery(R.TransactionAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListTransactionAccountsHook() {
  return async (...props: Parameters<typeof R.TransactionAccountSpec.list>) => {
    const res = await R.TransactionAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveTransactionAccount<
  TSelect = Awaited<ReturnType<(typeof R)['TransactionAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.TransactionAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.TransactionAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveTransactionAccountHook() {
  return async (...props: Parameters<typeof R.TransactionAccountSpec.retrieve>) => {
    const res = await R.TransactionAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useGetBankName<TSelect = Awaited<ReturnType<(typeof R)['GetBankNameSpec']['get']>>>(
  ...props: Parameters<typeof R.GetBankNameSpec.getQ<TSelect>>
) {
  return useQuery(R.GetBankNameSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetBankNameHook() {
  return async (...props: Parameters<typeof R.GetBankNameSpec.get>) => {
    const res = await R.GetBankNameSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetStatementPDF<
  TSelect = Awaited<ReturnType<(typeof R)['GetStatementPDFSpec']['get']>>,
>(...props: Parameters<typeof R.GetStatementPDFSpec.getQ<TSelect>>) {
  return useQuery(R.GetStatementPDFSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetStatementPDFHook() {
  return async (...props: Parameters<typeof R.GetStatementPDFSpec.get>) => {
    const res = await R.GetStatementPDFSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListBankAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BankAccountSpec']['list']>>>,
>(...props: Parameters<typeof R.BankAccountSpec.listQ<TSelect>>) {
  return useQuery(R.BankAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBankAccountsHook() {
  return async (...props: Parameters<typeof R.BankAccountSpec.list>) => {
    const res = await R.BankAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBankAccount<
  TSelect = Awaited<ReturnType<(typeof R)['BankAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BankAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BankAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBankAccountHook() {
  return async (...props: Parameters<typeof R.BankAccountSpec.retrieve>) => {
    const res = await R.BankAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateBankAccountHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BankAccountSpec.update>) => {
    const res = await R.BankAccountSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.BankAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyBankAccountHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BankAccountSpec.delete>) => {
    const res = await R.BankAccountSpec.delete(...props)
    R.BankAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateBankAccountHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BankAccountSpec.create>) => {
    const res = await R.BankAccountSpec.create(...props)
    R.BankAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetBankAccountArchiveRestrictions<
  TSelect = Awaited<ReturnType<(typeof R)['BankAccountSpec']['archive_restrictions']>>,
>(...props: Parameters<typeof R.BankAccountSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.BankAccountSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useListControlledPayeeBankAccounts<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['ControlledPayeeBankAccountSpec']['list']>>
  >,
>(...props: Parameters<typeof R.ControlledPayeeBankAccountSpec.listQ<TSelect>>) {
  return useQuery(R.ControlledPayeeBankAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListControlledPayeeBankAccountsHook() {
  return async (...props: Parameters<typeof R.ControlledPayeeBankAccountSpec.list>) => {
    const res = await R.ControlledPayeeBankAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveControlledPayeeBankAccount<
  TSelect = Awaited<ReturnType<(typeof R)['ControlledPayeeBankAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ControlledPayeeBankAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ControlledPayeeBankAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveControlledPayeeBankAccountHook() {
  return async (...props: Parameters<typeof R.ControlledPayeeBankAccountSpec.retrieve>) => {
    const res = await R.ControlledPayeeBankAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateControlledPayeeBankAccountHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ControlledPayeeBankAccountSpec.update>) => {
    const res = await R.ControlledPayeeBankAccountSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ControlledPayeeBankAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateControlledPayeeBankAccountHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ControlledPayeeBankAccountSpec.create>) => {
    const res = await R.ControlledPayeeBankAccountSpec.create(...props)
    R.ControlledPayeeBankAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetControlledPayeeBankAccountArchiveRestrictions<
  TSelect = Awaited<
    ReturnType<(typeof R)['ControlledPayeeBankAccountSpec']['archive_restrictions']>
  >,
>(...props: Parameters<typeof R.ControlledPayeeBankAccountSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.ControlledPayeeBankAccountSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useListPayeeBankAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['PayeeBankAccountSpec']['list']>>>,
>(...props: Parameters<typeof R.PayeeBankAccountSpec.listQ<TSelect>>) {
  return useQuery(R.PayeeBankAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPayeeBankAccountsHook() {
  return async (...props: Parameters<typeof R.PayeeBankAccountSpec.list>) => {
    const res = await R.PayeeBankAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePayeeBankAccount<
  TSelect = Awaited<ReturnType<(typeof R)['PayeeBankAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PayeeBankAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PayeeBankAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePayeeBankAccountHook() {
  return async (...props: Parameters<typeof R.PayeeBankAccountSpec.retrieve>) => {
    const res = await R.PayeeBankAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useGetPayeeBankAccountArchiveRestrictions<
  TSelect = Awaited<ReturnType<(typeof R)['PayeeBankAccountSpec']['archive_restrictions']>>,
>(...props: Parameters<typeof R.PayeeBankAccountSpec.archive_restrictionsQ<TSelect>>) {
  return useQuery(R.PayeeBankAccountSpec.archive_restrictionsQ<TSelect>(...props))
}

// ~~~~~~

export function useListCashBackPayments<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CashBackPaymentSpec']['list']>>>,
>(...props: Parameters<typeof R.CashBackPaymentSpec.listQ<TSelect>>) {
  return useQuery(R.CashBackPaymentSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCashBackPaymentsHook() {
  return async (...props: Parameters<typeof R.CashBackPaymentSpec.list>) => {
    const res = await R.CashBackPaymentSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCashBackPayment<
  TSelect = Awaited<ReturnType<(typeof R)['CashBackPaymentSpec']['retrieve']>>,
>(...props: Parameters<typeof R.CashBackPaymentSpec.retrieveQ<TSelect>>) {
  return useQuery(R.CashBackPaymentSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCashBackPaymentHook() {
  return async (...props: Parameters<typeof R.CashBackPaymentSpec.retrieve>) => {
    const res = await R.CashBackPaymentSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListYieldPayments<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['YieldPaymentSpec']['list']>>>,
>(...props: Parameters<typeof R.YieldPaymentSpec.listQ<TSelect>>) {
  return useQuery(R.YieldPaymentSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListYieldPaymentsHook() {
  return async (...props: Parameters<typeof R.YieldPaymentSpec.list>) => {
    const res = await R.YieldPaymentSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveYieldPayment<
  TSelect = Awaited<ReturnType<(typeof R)['YieldPaymentSpec']['retrieve']>>,
>(...props: Parameters<typeof R.YieldPaymentSpec.retrieveQ<TSelect>>) {
  return useQuery(R.YieldPaymentSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveYieldPaymentHook() {
  return async (...props: Parameters<typeof R.YieldPaymentSpec.retrieve>) => {
    const res = await R.YieldPaymentSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useGetProjectBudgetCost<
  TSelect = Awaited<ReturnType<(typeof R)['GetProjectBudgetCostSpec']['get']>>,
>(...props: Parameters<typeof R.GetProjectBudgetCostSpec.getQ<TSelect>>) {
  return useQuery(R.GetProjectBudgetCostSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetProjectBudgetCostHook() {
  return async (...props: Parameters<typeof R.GetProjectBudgetCostSpec.get>) => {
    const res = await R.GetProjectBudgetCostSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListBudgetConfigs<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BudgetConfigSpec']['list']>>>,
>(...props: Parameters<typeof R.BudgetConfigSpec.listQ<TSelect>>) {
  return useQuery(R.BudgetConfigSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBudgetConfigsHook() {
  return async (...props: Parameters<typeof R.BudgetConfigSpec.list>) => {
    const res = await R.BudgetConfigSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBudgetConfig<
  TSelect = Awaited<ReturnType<(typeof R)['BudgetConfigSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BudgetConfigSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BudgetConfigSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBudgetConfigHook() {
  return async (...props: Parameters<typeof R.BudgetConfigSpec.retrieve>) => {
    const res = await R.BudgetConfigSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateBudgetConfigHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BudgetConfigSpec.update>) => {
    const res = await R.BudgetConfigSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.BudgetConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateBudgetConfigHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BudgetConfigSpec.create>) => {
    const res = await R.BudgetConfigSpec.create(...props)
    R.BudgetConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonBudgetConfig<
  TSelect = Awaited<ReturnType<(typeof R)['BudgetConfigSpec']['singleton']>>,
>(...props: Parameters<typeof R.BudgetConfigSpec.singletonQ<TSelect>>) {
  return useQuery(R.BudgetConfigSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectBudgetItems<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ProjectBudgetItemSpec']['list']>>>,
>(...props: Parameters<typeof R.ProjectBudgetItemSpec.listQ<TSelect>>) {
  return useQuery(R.ProjectBudgetItemSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectBudgetItemsHook() {
  return async (...props: Parameters<typeof R.ProjectBudgetItemSpec.list>) => {
    const res = await R.ProjectBudgetItemSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectBudgetItem<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectBudgetItemSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectBudgetItemSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectBudgetItemSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectBudgetItemHook() {
  return async (...props: Parameters<typeof R.ProjectBudgetItemSpec.retrieve>) => {
    const res = await R.ProjectBudgetItemSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateProjectBudgetItemHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectBudgetItemSpec.update>) => {
    const res = await R.ProjectBudgetItemSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ProjectBudgetItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyProjectBudgetItemHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectBudgetItemSpec.delete>) => {
    const res = await R.ProjectBudgetItemSpec.delete(...props)
    R.ProjectBudgetItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateProjectBudgetItemHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectBudgetItemSpec.create>) => {
    const res = await R.ProjectBudgetItemSpec.create(...props)
    R.ProjectBudgetItemSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListProjectBudgets<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ProjectBudgetSpec']['list']>>>,
>(...props: Parameters<typeof R.ProjectBudgetSpec.listQ<TSelect>>) {
  return useQuery(R.ProjectBudgetSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectBudgetsHook() {
  return async (...props: Parameters<typeof R.ProjectBudgetSpec.list>) => {
    const res = await R.ProjectBudgetSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectBudget<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectBudgetSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectBudgetSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectBudgetSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectBudgetHook() {
  return async (...props: Parameters<typeof R.ProjectBudgetSpec.retrieve>) => {
    const res = await R.ProjectBudgetSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateProjectBudgetHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectBudgetSpec.update>) => {
    const res = await R.ProjectBudgetSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ProjectBudgetSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateProjectBudgetHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProjectBudgetSpec.create>) => {
    const res = await R.ProjectBudgetSpec.create(...props)
    R.ProjectBudgetSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListTasks<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['TaskSpec']['list']>>>,
>(...props: Parameters<typeof R.TaskSpec.listQ<TSelect>>) {
  return useQuery(R.TaskSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListTasksHook() {
  return async (...props: Parameters<typeof R.TaskSpec.list>) => {
    const res = await R.TaskSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveTask<TSelect = Awaited<ReturnType<(typeof R)['TaskSpec']['retrieve']>>>(
  ...props: Parameters<typeof R.TaskSpec.retrieveQ<TSelect>>
) {
  return useQuery(R.TaskSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveTaskHook() {
  return async (...props: Parameters<typeof R.TaskSpec.retrieve>) => {
    const res = await R.TaskSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.FileSpec.create>) => {
    const res = await R.FileSpec.create(...props)
    R.FileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useFileDownloadHook() {
  return async (...props: Parameters<typeof R.FileSpec.download>) => {
    const res = await R.FileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useFileDownload<TSelect = Awaited<ReturnType<(typeof R)['FileSpec']['download']>>>(
  ...props: Parameters<typeof R.FileSpec.downloadQ<TSelect>>
) {
  return useQuery(R.FileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListBusinessProfiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BusinessProfileSpec']['list']>>>,
>(...props: Parameters<typeof R.BusinessProfileSpec.listQ<TSelect>>) {
  return useQuery(R.BusinessProfileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBusinessProfilesHook() {
  return async (...props: Parameters<typeof R.BusinessProfileSpec.list>) => {
    const res = await R.BusinessProfileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBusinessProfile<
  TSelect = Awaited<ReturnType<(typeof R)['BusinessProfileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BusinessProfileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BusinessProfileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBusinessProfileHook() {
  return async (...props: Parameters<typeof R.BusinessProfileSpec.retrieve>) => {
    const res = await R.BusinessProfileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateBusinessProfileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessProfileSpec.create>) => {
    const res = await R.BusinessProfileSpec.create(...props)
    R.BusinessProfileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonOnboardingFlow<
  TSelect = Awaited<ReturnType<(typeof R)['OnboardingFlowSpec']['singleton']>>,
>(...props: Parameters<typeof R.OnboardingFlowSpec.singletonQ<TSelect>>) {
  return useQuery(R.OnboardingFlowSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useWhoAmI<TSelect = Awaited<ReturnType<(typeof R)['WhoAmISpec']['get']>>>(
  ...props: Parameters<typeof R.WhoAmISpec.getQ<TSelect>>
) {
  return useQuery(R.WhoAmISpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useWhoAmIHook() {
  return async (...props: Parameters<typeof R.WhoAmISpec.get>) => {
    const res = await R.WhoAmISpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListEmployeeProfiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EmployeeProfileSpec']['list']>>>,
>(...props: Parameters<typeof R.EmployeeProfileSpec.listQ<TSelect>>) {
  return useQuery(R.EmployeeProfileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEmployeeProfilesHook() {
  return async (...props: Parameters<typeof R.EmployeeProfileSpec.list>) => {
    const res = await R.EmployeeProfileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEmployeeProfile<
  TSelect = Awaited<ReturnType<(typeof R)['EmployeeProfileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EmployeeProfileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EmployeeProfileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEmployeeProfileHook() {
  return async (...props: Parameters<typeof R.EmployeeProfileSpec.retrieve>) => {
    const res = await R.EmployeeProfileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListOnboardingSurveys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['OnboardingSurveySpec']['list']>>>,
>(...props: Parameters<typeof R.OnboardingSurveySpec.listQ<TSelect>>) {
  return useQuery(R.OnboardingSurveySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListOnboardingSurveysHook() {
  return async (...props: Parameters<typeof R.OnboardingSurveySpec.list>) => {
    const res = await R.OnboardingSurveySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveOnboardingSurvey<
  TSelect = Awaited<ReturnType<(typeof R)['OnboardingSurveySpec']['retrieve']>>,
>(...props: Parameters<typeof R.OnboardingSurveySpec.retrieveQ<TSelect>>) {
  return useQuery(R.OnboardingSurveySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveOnboardingSurveyHook() {
  return async (...props: Parameters<typeof R.OnboardingSurveySpec.retrieve>) => {
    const res = await R.OnboardingSurveySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateOnboardingSurveyHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.OnboardingSurveySpec.update>) => {
    const res = await R.OnboardingSurveySpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.OnboardingSurveySpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateOnboardingSurveyHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.OnboardingSurveySpec.create>) => {
    const res = await R.OnboardingSurveySpec.create(...props)
    R.OnboardingSurveySpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListTermsOfServiceAcceptances<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['TermsOfServiceAcceptanceSpec']['list']>>
  >,
>(...props: Parameters<typeof R.TermsOfServiceAcceptanceSpec.listQ<TSelect>>) {
  return useQuery(R.TermsOfServiceAcceptanceSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListTermsOfServiceAcceptancesHook() {
  return async (...props: Parameters<typeof R.TermsOfServiceAcceptanceSpec.list>) => {
    const res = await R.TermsOfServiceAcceptanceSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveTermsOfServiceAcceptance<
  TSelect = Awaited<ReturnType<(typeof R)['TermsOfServiceAcceptanceSpec']['retrieve']>>,
>(...props: Parameters<typeof R.TermsOfServiceAcceptanceSpec.retrieveQ<TSelect>>) {
  return useQuery(R.TermsOfServiceAcceptanceSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveTermsOfServiceAcceptanceHook() {
  return async (...props: Parameters<typeof R.TermsOfServiceAcceptanceSpec.retrieve>) => {
    const res = await R.TermsOfServiceAcceptanceSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateTermsOfServiceAcceptanceHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.TermsOfServiceAcceptanceSpec.create>) => {
    const res = await R.TermsOfServiceAcceptanceSpec.create(...props)
    R.TermsOfServiceAcceptanceSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListTermsOfServices<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['TermsOfServiceSpec']['list']>>>,
>(...props: Parameters<typeof R.TermsOfServiceSpec.listQ<TSelect>>) {
  return useQuery(R.TermsOfServiceSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListTermsOfServicesHook() {
  return async (...props: Parameters<typeof R.TermsOfServiceSpec.list>) => {
    const res = await R.TermsOfServiceSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveTermsOfService<
  TSelect = Awaited<ReturnType<(typeof R)['TermsOfServiceSpec']['retrieve']>>,
>(...props: Parameters<typeof R.TermsOfServiceSpec.retrieveQ<TSelect>>) {
  return useQuery(R.TermsOfServiceSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveTermsOfServiceHook() {
  return async (...props: Parameters<typeof R.TermsOfServiceSpec.retrieve>) => {
    const res = await R.TermsOfServiceSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListComplianceDocumentEmbeddedFiles<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['ComplianceDocumentEmbeddedFileSpec']['list']>>
  >,
>(...props: Parameters<typeof R.ComplianceDocumentEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.ComplianceDocumentEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListComplianceDocumentEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.ComplianceDocumentEmbeddedFileSpec.list>) => {
    const res = await R.ComplianceDocumentEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveComplianceDocumentEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['ComplianceDocumentEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ComplianceDocumentEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ComplianceDocumentEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveComplianceDocumentEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.ComplianceDocumentEmbeddedFileSpec.retrieve>) => {
    const res = await R.ComplianceDocumentEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useComplianceDocumentEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.ComplianceDocumentEmbeddedFileSpec.download>) => {
    const res = await R.ComplianceDocumentEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useComplianceDocumentEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['ComplianceDocumentEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.ComplianceDocumentEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.ComplianceDocumentEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListComplianceDocuments<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ComplianceDocumentSpec']['list']>>>,
>(...props: Parameters<typeof R.ComplianceDocumentSpec.listQ<TSelect>>) {
  return useQuery(R.ComplianceDocumentSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListComplianceDocumentsHook() {
  return async (...props: Parameters<typeof R.ComplianceDocumentSpec.list>) => {
    const res = await R.ComplianceDocumentSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveComplianceDocument<
  TSelect = Awaited<ReturnType<(typeof R)['ComplianceDocumentSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ComplianceDocumentSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ComplianceDocumentSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveComplianceDocumentHook() {
  return async (...props: Parameters<typeof R.ComplianceDocumentSpec.retrieve>) => {
    const res = await R.ComplianceDocumentSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyComplianceDocumentHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ComplianceDocumentSpec.delete>) => {
    const res = await R.ComplianceDocumentSpec.delete(...props)
    R.ComplianceDocumentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateComplianceDocumentHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ComplianceDocumentSpec.create>) => {
    const res = await R.ComplianceDocumentSpec.create(...props)
    R.ComplianceDocumentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListComplianceSettingsLists<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ComplianceSettingsListSpec']['list']>>>,
>(...props: Parameters<typeof R.ComplianceSettingsListSpec.listQ<TSelect>>) {
  return useQuery(R.ComplianceSettingsListSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListComplianceSettingsListsHook() {
  return async (...props: Parameters<typeof R.ComplianceSettingsListSpec.list>) => {
    const res = await R.ComplianceSettingsListSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveComplianceSettingsList<
  TSelect = Awaited<ReturnType<(typeof R)['ComplianceSettingsListSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ComplianceSettingsListSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ComplianceSettingsListSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveComplianceSettingsListHook() {
  return async (...props: Parameters<typeof R.ComplianceSettingsListSpec.retrieve>) => {
    const res = await R.ComplianceSettingsListSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateComplianceSettingsListHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ComplianceSettingsListSpec.update>) => {
    const res = await R.ComplianceSettingsListSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ComplianceSettingsListSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonComplianceSettingsList<
  TSelect = Awaited<ReturnType<(typeof R)['ComplianceSettingsListSpec']['singleton']>>,
>(...props: Parameters<typeof R.ComplianceSettingsListSpec.singletonQ<TSelect>>) {
  return useQuery(R.ComplianceSettingsListSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListComplianceStatusSummarys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ComplianceStatusSummarySpec']['list']>>>,
>(...props: Parameters<typeof R.ComplianceStatusSummarySpec.listQ<TSelect>>) {
  return useQuery(R.ComplianceStatusSummarySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListComplianceStatusSummarysHook() {
  return async (...props: Parameters<typeof R.ComplianceStatusSummarySpec.list>) => {
    const res = await R.ComplianceStatusSummarySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveComplianceStatusSummary<
  TSelect = Awaited<ReturnType<(typeof R)['ComplianceStatusSummarySpec']['retrieve']>>,
>(...props: Parameters<typeof R.ComplianceStatusSummarySpec.retrieveQ<TSelect>>) {
  return useQuery(R.ComplianceStatusSummarySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveComplianceStatusSummaryHook() {
  return async (...props: Parameters<typeof R.ComplianceStatusSummarySpec.retrieve>) => {
    const res = await R.ComplianceStatusSummarySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListPayeeComplianceRequirements<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['PayeeComplianceRequirementSpec']['list']>>
  >,
>(...props: Parameters<typeof R.PayeeComplianceRequirementSpec.listQ<TSelect>>) {
  return useQuery(R.PayeeComplianceRequirementSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPayeeComplianceRequirementsHook() {
  return async (...props: Parameters<typeof R.PayeeComplianceRequirementSpec.list>) => {
    const res = await R.PayeeComplianceRequirementSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePayeeComplianceRequirement<
  TSelect = Awaited<ReturnType<(typeof R)['PayeeComplianceRequirementSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PayeeComplianceRequirementSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PayeeComplianceRequirementSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePayeeComplianceRequirementHook() {
  return async (...props: Parameters<typeof R.PayeeComplianceRequirementSpec.retrieve>) => {
    const res = await R.PayeeComplianceRequirementSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdatePayeeComplianceRequirementHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PayeeComplianceRequirementSpec.update>) => {
    const res = await R.PayeeComplianceRequirementSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.PayeeComplianceRequirementSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useListRequirementLists<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['RequirementListSpec']['list']>>>,
>(...props: Parameters<typeof R.RequirementListSpec.listQ<TSelect>>) {
  return useQuery(R.RequirementListSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListRequirementListsHook() {
  return async (...props: Parameters<typeof R.RequirementListSpec.list>) => {
    const res = await R.RequirementListSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveRequirementList<
  TSelect = Awaited<ReturnType<(typeof R)['RequirementListSpec']['retrieve']>>,
>(...props: Parameters<typeof R.RequirementListSpec.retrieveQ<TSelect>>) {
  return useQuery(R.RequirementListSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveRequirementListHook() {
  return async (...props: Parameters<typeof R.RequirementListSpec.retrieve>) => {
    const res = await R.RequirementListSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useGetCombinedComplianceSettingsListAsPayee<
  TSelect = Awaited<ReturnType<(typeof R)['GetCombinedComplianceSettingsListAsPayeeSpec']['get']>>,
>(...props: Parameters<typeof R.GetCombinedComplianceSettingsListAsPayeeSpec.getQ<TSelect>>) {
  return useQuery(R.GetCombinedComplianceSettingsListAsPayeeSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetCombinedComplianceSettingsListAsPayeeHook() {
  return async (
    ...props: Parameters<typeof R.GetCombinedComplianceSettingsListAsPayeeSpec.get>
  ) => {
    const res = await R.GetCombinedComplianceSettingsListAsPayeeSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetComplianceSettingsListForPayee<
  TSelect = Awaited<ReturnType<(typeof R)['GetComplianceSettingsListForPayeeSpec']['get']>>,
>(...props: Parameters<typeof R.GetComplianceSettingsListForPayeeSpec.getQ<TSelect>>) {
  return useQuery(R.GetComplianceSettingsListForPayeeSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetComplianceSettingsListForPayeeHook() {
  return async (...props: Parameters<typeof R.GetComplianceSettingsListForPayeeSpec.get>) => {
    const res = await R.GetComplianceSettingsListForPayeeSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListComplianceSettingsListPayeeOverrides<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['ComplianceSettingsListPayeeOverrideSpec']['list']>>
  >,
>(...props: Parameters<typeof R.ComplianceSettingsListPayeeOverrideSpec.listQ<TSelect>>) {
  return useQuery(R.ComplianceSettingsListPayeeOverrideSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListComplianceSettingsListPayeeOverridesHook() {
  return async (...props: Parameters<typeof R.ComplianceSettingsListPayeeOverrideSpec.list>) => {
    const res = await R.ComplianceSettingsListPayeeOverrideSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveComplianceSettingsListPayeeOverride<
  TSelect = Awaited<ReturnType<(typeof R)['ComplianceSettingsListPayeeOverrideSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ComplianceSettingsListPayeeOverrideSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ComplianceSettingsListPayeeOverrideSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveComplianceSettingsListPayeeOverrideHook() {
  return async (
    ...props: Parameters<typeof R.ComplianceSettingsListPayeeOverrideSpec.retrieve>
  ) => {
    const res = await R.ComplianceSettingsListPayeeOverrideSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateComplianceSettingsListPayeeOverrideHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ComplianceSettingsListPayeeOverrideSpec.update>) => {
    const res = await R.ComplianceSettingsListPayeeOverrideSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.ComplianceSettingsListPayeeOverrideSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyComplianceSettingsListPayeeOverrideHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ComplianceSettingsListPayeeOverrideSpec.delete>) => {
    const res = await R.ComplianceSettingsListPayeeOverrideSpec.delete(...props)
    R.ComplianceSettingsListPayeeOverrideSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateComplianceSettingsListPayeeOverrideHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ComplianceSettingsListPayeeOverrideSpec.create>) => {
    const res = await R.ComplianceSettingsListPayeeOverrideSpec.create(...props)
    R.ComplianceSettingsListPayeeOverrideSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListEstimateApprovalSignatures<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['EstimateApprovalSignatureSpec']['list']>>
  >,
>(...props: Parameters<typeof R.EstimateApprovalSignatureSpec.listQ<TSelect>>) {
  return useQuery(R.EstimateApprovalSignatureSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateApprovalSignaturesHook() {
  return async (...props: Parameters<typeof R.EstimateApprovalSignatureSpec.list>) => {
    const res = await R.EstimateApprovalSignatureSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimateApprovalSignature<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateApprovalSignatureSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimateApprovalSignatureSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimateApprovalSignatureSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimateApprovalSignatureHook() {
  return async (...props: Parameters<typeof R.EstimateApprovalSignatureSpec.retrieve>) => {
    const res = await R.EstimateApprovalSignatureSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListEstimateEmails<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimateEmailSpec']['list']>>>,
>(...props: Parameters<typeof R.EstimateEmailSpec.listQ<TSelect>>) {
  return useQuery(R.EstimateEmailSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateEmailsHook() {
  return async (...props: Parameters<typeof R.EstimateEmailSpec.list>) => {
    const res = await R.EstimateEmailSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimateEmail<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateEmailSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimateEmailSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimateEmailSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimateEmailHook() {
  return async (...props: Parameters<typeof R.EstimateEmailSpec.retrieve>) => {
    const res = await R.EstimateEmailSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListEstimateEmailViews<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimateEmailViewSpec']['list']>>>,
>(...props: Parameters<typeof R.EstimateEmailViewSpec.listQ<TSelect>>) {
  return useQuery(R.EstimateEmailViewSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateEmailViewsHook() {
  return async (...props: Parameters<typeof R.EstimateEmailViewSpec.list>) => {
    const res = await R.EstimateEmailViewSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimateEmailView<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateEmailViewSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimateEmailViewSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimateEmailViewSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimateEmailViewHook() {
  return async (...props: Parameters<typeof R.EstimateEmailViewSpec.retrieve>) => {
    const res = await R.EstimateEmailViewSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListEstimateEmbeddedFiles<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimateEmbeddedFileSpec']['list']>>>,
>(...props: Parameters<typeof R.EstimateEmbeddedFileSpec.listQ<TSelect>>) {
  return useQuery(R.EstimateEmbeddedFileSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateEmbeddedFilesHook() {
  return async (...props: Parameters<typeof R.EstimateEmbeddedFileSpec.list>) => {
    const res = await R.EstimateEmbeddedFileSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimateEmbeddedFile<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateEmbeddedFileSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimateEmbeddedFileSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimateEmbeddedFileSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimateEmbeddedFileHook() {
  return async (...props: Parameters<typeof R.EstimateEmbeddedFileSpec.retrieve>) => {
    const res = await R.EstimateEmbeddedFileSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateEstimateEmbeddedFileHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateEmbeddedFileSpec.create>) => {
    const res = await R.EstimateEmbeddedFileSpec.create(...props)
    R.EstimateEmbeddedFileSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useEstimateEmbeddedFileDownloadHook() {
  return async (...props: Parameters<typeof R.EstimateEmbeddedFileSpec.download>) => {
    const res = await R.EstimateEmbeddedFileSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useEstimateEmbeddedFileDownload<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateEmbeddedFileSpec']['download']>>,
>(...props: Parameters<typeof R.EstimateEmbeddedFileSpec.downloadQ<TSelect>>) {
  return useQuery(R.EstimateEmbeddedFileSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimatePageVisits<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimatePageVisitSpec']['list']>>>,
>(...props: Parameters<typeof R.EstimatePageVisitSpec.listQ<TSelect>>) {
  return useQuery(R.EstimatePageVisitSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimatePageVisitsHook() {
  return async (...props: Parameters<typeof R.EstimatePageVisitSpec.list>) => {
    const res = await R.EstimatePageVisitSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimatePageVisit<
  TSelect = Awaited<ReturnType<(typeof R)['EstimatePageVisitSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimatePageVisitSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimatePageVisitSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimatePageVisitHook() {
  return async (...props: Parameters<typeof R.EstimatePageVisitSpec.retrieve>) => {
    const res = await R.EstimatePageVisitSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateEstimatePageVisitHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimatePageVisitSpec.create>) => {
    const res = await R.EstimatePageVisitSpec.create(...props)
    R.EstimatePageVisitSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListEstimates<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimateSpec']['list']>>>,
>(...props: Parameters<typeof R.EstimateSpec.listQ<TSelect>>) {
  return useQuery(R.EstimateSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimatesHook() {
  return async (...props: Parameters<typeof R.EstimateSpec.list>) => {
    const res = await R.EstimateSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimate<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimateSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimateSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimateHook() {
  return async (...props: Parameters<typeof R.EstimateSpec.retrieve>) => {
    const res = await R.EstimateSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateEstimateHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateSpec.update>) => {
    const res = await R.EstimateSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.EstimateSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyEstimateHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateSpec.delete>) => {
    const res = await R.EstimateSpec.delete(...props)
    R.EstimateSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateEstimateHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateSpec.create>) => {
    const res = await R.EstimateSpec.create(...props)
    R.EstimateSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useEstimatePdf<TSelect = Awaited<ReturnType<(typeof R)['EstimateSpec']['pdf']>>>(
  ...props: Parameters<typeof R.EstimateSpec.pdfQ<TSelect>>
) {
  return useQuery(R.EstimateSpec.pdfQ<TSelect>(...props))
}

// ~~~~~~

export function useEstimatePdfHook() {
  return async (...props: Parameters<typeof R.EstimateSpec.pdf>) => {
    const res = await R.EstimateSpec.pdf(...props)
    return res
  }
}

// ~~~~~~

export function useGetEstimateCoverPagePdfPreview<
  TSelect = Awaited<ReturnType<(typeof R)['GetEstimateCoverPagePdfPreviewSpec']['get']>>,
>(...props: Parameters<typeof R.GetEstimateCoverPagePdfPreviewSpec.getQ<TSelect>>) {
  return useQuery(R.GetEstimateCoverPagePdfPreviewSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetEstimateCoverPagePdfPreviewHook() {
  return async (...props: Parameters<typeof R.GetEstimateCoverPagePdfPreviewSpec.get>) => {
    const res = await R.GetEstimateCoverPagePdfPreviewSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetEstimateInputFromFile<
  TSelect = Awaited<ReturnType<(typeof R)['GetEstimateInputFromFileSpec']['get']>>,
>(...props: Parameters<typeof R.GetEstimateInputFromFileSpec.getQ<TSelect>>) {
  return useQuery(R.GetEstimateInputFromFileSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetEstimateInputFromFileHook() {
  return async (...props: Parameters<typeof R.GetEstimateInputFromFileSpec.get>) => {
    const res = await R.GetEstimateInputFromFileSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetNextEstimateNumber<
  TSelect = Awaited<ReturnType<(typeof R)['GetNextEstimateNumberSpec']['get']>>,
>(...props: Parameters<typeof R.GetNextEstimateNumberSpec.getQ<TSelect>>) {
  return useQuery(R.GetNextEstimateNumberSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetNextEstimateNumberHook() {
  return async (...props: Parameters<typeof R.GetNextEstimateNumberSpec.get>) => {
    const res = await R.GetNextEstimateNumberSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListEstimateConfigs<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimateConfigSpec']['list']>>>,
>(...props: Parameters<typeof R.EstimateConfigSpec.listQ<TSelect>>) {
  return useQuery(R.EstimateConfigSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateConfigsHook() {
  return async (...props: Parameters<typeof R.EstimateConfigSpec.list>) => {
    const res = await R.EstimateConfigSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimateConfig<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateConfigSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimateConfigSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimateConfigSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimateConfigHook() {
  return async (...props: Parameters<typeof R.EstimateConfigSpec.retrieve>) => {
    const res = await R.EstimateConfigSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateEstimateConfigHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateConfigSpec.update>) => {
    const res = await R.EstimateConfigSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.EstimateConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateEstimateConfigHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateConfigSpec.create>) => {
    const res = await R.EstimateConfigSpec.create(...props)
    R.EstimateConfigSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonEstimateConfig<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateConfigSpec']['singleton']>>,
>(...props: Parameters<typeof R.EstimateConfigSpec.singletonQ<TSelect>>) {
  return useQuery(R.EstimateConfigSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateCoverPagePhotos<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimateCoverPagePhotoSpec']['list']>>>,
>(...props: Parameters<typeof R.EstimateCoverPagePhotoSpec.listQ<TSelect>>) {
  return useQuery(R.EstimateCoverPagePhotoSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateCoverPagePhotosHook() {
  return async (...props: Parameters<typeof R.EstimateCoverPagePhotoSpec.list>) => {
    const res = await R.EstimateCoverPagePhotoSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimateCoverPagePhoto<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateCoverPagePhotoSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimateCoverPagePhotoSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimateCoverPagePhotoSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimateCoverPagePhotoHook() {
  return async (...props: Parameters<typeof R.EstimateCoverPagePhotoSpec.retrieve>) => {
    const res = await R.EstimateCoverPagePhotoSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useDestroyEstimateCoverPagePhotoHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateCoverPagePhotoSpec.delete>) => {
    const res = await R.EstimateCoverPagePhotoSpec.delete(...props)
    R.EstimateCoverPagePhotoSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateEstimateCoverPagePhotoHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateCoverPagePhotoSpec.create>) => {
    const res = await R.EstimateCoverPagePhotoSpec.create(...props)
    R.EstimateCoverPagePhotoSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useEstimateCoverPagePhotoDownloadHook() {
  return async (...props: Parameters<typeof R.EstimateCoverPagePhotoSpec.download>) => {
    const res = await R.EstimateCoverPagePhotoSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useEstimateCoverPagePhotoDownload<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateCoverPagePhotoSpec']['download']>>,
>(...props: Parameters<typeof R.EstimateCoverPagePhotoSpec.downloadQ<TSelect>>) {
  return useQuery(R.EstimateCoverPagePhotoSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateCoverPages<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimateCoverPageSpec']['list']>>>,
>(...props: Parameters<typeof R.EstimateCoverPageSpec.listQ<TSelect>>) {
  return useQuery(R.EstimateCoverPageSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimateCoverPagesHook() {
  return async (...props: Parameters<typeof R.EstimateCoverPageSpec.list>) => {
    const res = await R.EstimateCoverPageSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimateCoverPage<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateCoverPageSpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimateCoverPageSpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimateCoverPageSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimateCoverPageHook() {
  return async (...props: Parameters<typeof R.EstimateCoverPageSpec.retrieve>) => {
    const res = await R.EstimateCoverPageSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateEstimateCoverPageHook(options?: {
  disableAutoInvalidate?: boolean
}) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateCoverPageSpec.update>) => {
    const res = await R.EstimateCoverPageSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.EstimateCoverPageSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyEstimateCoverPageHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateCoverPageSpec.delete>) => {
    const res = await R.EstimateCoverPageSpec.delete(...props)
    R.EstimateCoverPageSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateEstimateCoverPageHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.EstimateCoverPageSpec.create>) => {
    const res = await R.EstimateCoverPageSpec.create(...props)
    R.EstimateCoverPageSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useEstimateCoverPageDownloadHook() {
  return async (...props: Parameters<typeof R.EstimateCoverPageSpec.download>) => {
    const res = await R.EstimateCoverPageSpec.download(...props)
    return res
  }
}

// ~~~~~~

export function useEstimateCoverPageDownload<
  TSelect = Awaited<ReturnType<(typeof R)['EstimateCoverPageSpec']['download']>>,
>(...props: Parameters<typeof R.EstimateCoverPageSpec.downloadQ<TSelect>>) {
  return useQuery(R.EstimateCoverPageSpec.downloadQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimatesAsPayeePageSummarys<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['EstimatesAsPayeePageSummarySpec']['list']>>
  >,
>(...props: Parameters<typeof R.EstimatesAsPayeePageSummarySpec.listQ<TSelect>>) {
  return useQuery(R.EstimatesAsPayeePageSummarySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimatesAsPayeePageSummarysHook() {
  return async (...props: Parameters<typeof R.EstimatesAsPayeePageSummarySpec.list>) => {
    const res = await R.EstimatesAsPayeePageSummarySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimatesAsPayeePageSummary<
  TSelect = Awaited<ReturnType<(typeof R)['EstimatesAsPayeePageSummarySpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimatesAsPayeePageSummarySpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimatesAsPayeePageSummarySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimatesAsPayeePageSummaryHook() {
  return async (...props: Parameters<typeof R.EstimatesAsPayeePageSummarySpec.retrieve>) => {
    const res = await R.EstimatesAsPayeePageSummarySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['AccountSpec']['list']>>>,
>(...props: Parameters<typeof R.AccountSpec.listQ<TSelect>>) {
  return useQuery(R.AccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListAccountsHook() {
  return async (...props: Parameters<typeof R.AccountSpec.list>) => {
    const res = await R.AccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveAccount<
  TSelect = Awaited<ReturnType<(typeof R)['AccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.AccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.AccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveAccountHook() {
  return async (...props: Parameters<typeof R.AccountSpec.retrieve>) => {
    const res = await R.AccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateAccountHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.AccountSpec.update>) => {
    const res = await R.AccountSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.AccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateAccountHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.AccountSpec.create>) => {
    const res = await R.AccountSpec.create(...props)
    R.AccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useListInvoiceOutboundSyncV2s<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceOutboundSyncV2Spec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceOutboundSyncV2Spec.listQ<TSelect>>) {
  return useQuery(R.InvoiceOutboundSyncV2Spec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceOutboundSyncV2sHook() {
  return async (...props: Parameters<typeof R.InvoiceOutboundSyncV2Spec.list>) => {
    const res = await R.InvoiceOutboundSyncV2Spec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceOutboundSyncV2<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceOutboundSyncV2Spec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceOutboundSyncV2Spec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceOutboundSyncV2Spec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceOutboundSyncV2Hook() {
  return async (...props: Parameters<typeof R.InvoiceOutboundSyncV2Spec.retrieve>) => {
    const res = await R.InvoiceOutboundSyncV2Spec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useGetLinkToken<
  TSelect = Awaited<ReturnType<(typeof R)['GetLinkTokenSpec']['get']>>,
>(...props: Parameters<typeof R.GetLinkTokenSpec.getQ<TSelect>>) {
  return useQuery(R.GetLinkTokenSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetLinkTokenHook() {
  return async (...props: Parameters<typeof R.GetLinkTokenSpec.get>) => {
    const res = await R.GetLinkTokenSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetProjectImportRequest<
  TSelect = Awaited<ReturnType<(typeof R)['GetProjectImportRequestSpec']['get']>>,
>(...props: Parameters<typeof R.GetProjectImportRequestSpec.getQ<TSelect>>) {
  return useQuery(R.GetProjectImportRequestSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetProjectImportRequestHook() {
  return async (...props: Parameters<typeof R.GetProjectImportRequestSpec.get>) => {
    const res = await R.GetProjectImportRequestSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetUpdatedTokenStatus<
  TSelect = Awaited<ReturnType<(typeof R)['GetUpdatedTokenStatusSpec']['get']>>,
>(...props: Parameters<typeof R.GetUpdatedTokenStatusSpec.getQ<TSelect>>) {
  return useQuery(R.GetUpdatedTokenStatusSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetUpdatedTokenStatusHook() {
  return async (...props: Parameters<typeof R.GetUpdatedTokenStatusSpec.get>) => {
    const res = await R.GetUpdatedTokenStatusSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListQBPullRequests<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['QBPullRequestSpec']['list']>>>,
>(...props: Parameters<typeof R.QBPullRequestSpec.listQ<TSelect>>) {
  return useQuery(R.QBPullRequestSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListQBPullRequestsHook() {
  return async (...props: Parameters<typeof R.QBPullRequestSpec.list>) => {
    const res = await R.QBPullRequestSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveQBPullRequest<
  TSelect = Awaited<ReturnType<(typeof R)['QBPullRequestSpec']['retrieve']>>,
>(...props: Parameters<typeof R.QBPullRequestSpec.retrieveQ<TSelect>>) {
  return useQuery(R.QBPullRequestSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveQBPullRequestHook() {
  return async (...props: Parameters<typeof R.QBPullRequestSpec.retrieve>) => {
    const res = await R.QBPullRequestSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListQBPushRequests<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['QBPushRequestSpec']['list']>>>,
>(...props: Parameters<typeof R.QBPushRequestSpec.listQ<TSelect>>) {
  return useQuery(R.QBPushRequestSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListQBPushRequestsHook() {
  return async (...props: Parameters<typeof R.QBPushRequestSpec.list>) => {
    const res = await R.QBPushRequestSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveQBPushRequest<
  TSelect = Awaited<ReturnType<(typeof R)['QBPushRequestSpec']['retrieve']>>,
>(...props: Parameters<typeof R.QBPushRequestSpec.retrieveQ<TSelect>>) {
  return useQuery(R.QBPushRequestSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveQBPushRequestHook() {
  return async (...props: Parameters<typeof R.QBPushRequestSpec.retrieve>) => {
    const res = await R.QBPushRequestSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListQboLinks<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['QboLinkSpec']['list']>>>,
>(...props: Parameters<typeof R.QboLinkSpec.listQ<TSelect>>) {
  return useQuery(R.QboLinkSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListQboLinksHook() {
  return async (...props: Parameters<typeof R.QboLinkSpec.list>) => {
    const res = await R.QboLinkSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveQboLink<
  TSelect = Awaited<ReturnType<(typeof R)['QboLinkSpec']['retrieve']>>,
>(...props: Parameters<typeof R.QboLinkSpec.retrieveQ<TSelect>>) {
  return useQuery(R.QboLinkSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveQboLinkHook() {
  return async (...props: Parameters<typeof R.QboLinkSpec.retrieve>) => {
    const res = await R.QboLinkSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListRawQboLinks<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['RawQboLinkSpec']['list']>>>,
>(...props: Parameters<typeof R.RawQboLinkSpec.listQ<TSelect>>) {
  return useQuery(R.RawQboLinkSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListRawQboLinksHook() {
  return async (...props: Parameters<typeof R.RawQboLinkSpec.list>) => {
    const res = await R.RawQboLinkSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveRawQboLink<
  TSelect = Awaited<ReturnType<(typeof R)['RawQboLinkSpec']['retrieve']>>,
>(...props: Parameters<typeof R.RawQboLinkSpec.retrieveQ<TSelect>>) {
  return useQuery(R.RawQboLinkSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveRawQboLinkHook() {
  return async (...props: Parameters<typeof R.RawQboLinkSpec.retrieve>) => {
    const res = await R.RawQboLinkSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListBillListRows<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BillListRowSpec']['list']>>>,
>(...props: Parameters<typeof R.BillListRowSpec.listQ<TSelect>>) {
  return useQuery(R.BillListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillListRowsHook() {
  return async (...props: Parameters<typeof R.BillListRowSpec.list>) => {
    const res = await R.BillListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBillListRow<
  TSelect = Awaited<ReturnType<(typeof R)['BillListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BillListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BillListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBillListRowHook() {
  return async (...props: Parameters<typeof R.BillListRowSpec.retrieve>) => {
    const res = await R.BillListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListCardTransactionListRows<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CardTransactionListRowSpec']['list']>>>,
>(...props: Parameters<typeof R.CardTransactionListRowSpec.listQ<TSelect>>) {
  return useQuery(R.CardTransactionListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCardTransactionListRowsHook() {
  return async (...props: Parameters<typeof R.CardTransactionListRowSpec.list>) => {
    const res = await R.CardTransactionListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCardTransactionListRow<
  TSelect = Awaited<ReturnType<(typeof R)['CardTransactionListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.CardTransactionListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.CardTransactionListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCardTransactionListRowHook() {
  return async (...props: Parameters<typeof R.CardTransactionListRowSpec.retrieve>) => {
    const res = await R.CardTransactionListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListClientListRows<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ClientListRowSpec']['list']>>>,
>(...props: Parameters<typeof R.ClientListRowSpec.listQ<TSelect>>) {
  return useQuery(R.ClientListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListClientListRowsHook() {
  return async (...props: Parameters<typeof R.ClientListRowSpec.list>) => {
    const res = await R.ClientListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveClientListRow<
  TSelect = Awaited<ReturnType<(typeof R)['ClientListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ClientListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ClientListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveClientListRowHook() {
  return async (...props: Parameters<typeof R.ClientListRowSpec.retrieve>) => {
    const res = await R.ClientListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListExpenseListRows<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ExpenseListRowSpec']['list']>>>,
>(...props: Parameters<typeof R.ExpenseListRowSpec.listQ<TSelect>>) {
  return useQuery(R.ExpenseListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListExpenseListRowsHook() {
  return async (...props: Parameters<typeof R.ExpenseListRowSpec.list>) => {
    const res = await R.ExpenseListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveExpenseListRow<
  TSelect = Awaited<ReturnType<(typeof R)['ExpenseListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ExpenseListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ExpenseListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveExpenseListRowHook() {
  return async (...props: Parameters<typeof R.ExpenseListRowSpec.retrieve>) => {
    const res = await R.ExpenseListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListGCSubcontractInlineListRows<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['GCSubcontractInlineListRowSpec']['list']>>
  >,
>(...props: Parameters<typeof R.GCSubcontractInlineListRowSpec.listQ<TSelect>>) {
  return useQuery(R.GCSubcontractInlineListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListGCSubcontractInlineListRowsHook() {
  return async (...props: Parameters<typeof R.GCSubcontractInlineListRowSpec.list>) => {
    const res = await R.GCSubcontractInlineListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveGCSubcontractInlineListRow<
  TSelect = Awaited<ReturnType<(typeof R)['GCSubcontractInlineListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.GCSubcontractInlineListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.GCSubcontractInlineListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveGCSubcontractInlineListRowHook() {
  return async (...props: Parameters<typeof R.GCSubcontractInlineListRowSpec.retrieve>) => {
    const res = await R.GCSubcontractInlineListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListInvoiceListRows<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoiceListRowSpec']['list']>>>,
>(...props: Parameters<typeof R.InvoiceListRowSpec.listQ<TSelect>>) {
  return useQuery(R.InvoiceListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoiceListRowsHook() {
  return async (...props: Parameters<typeof R.InvoiceListRowSpec.list>) => {
    const res = await R.InvoiceListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoiceListRow<
  TSelect = Awaited<ReturnType<(typeof R)['InvoiceListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoiceListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoiceListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoiceListRowHook() {
  return async (...props: Parameters<typeof R.InvoiceListRowSpec.retrieve>) => {
    const res = await R.InvoiceListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListInvoicePaymentFeeListRows<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['InvoicePaymentFeeListRowSpec']['list']>>
  >,
>(...props: Parameters<typeof R.InvoicePaymentFeeListRowSpec.listQ<TSelect>>) {
  return useQuery(R.InvoicePaymentFeeListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoicePaymentFeeListRowsHook() {
  return async (...props: Parameters<typeof R.InvoicePaymentFeeListRowSpec.list>) => {
    const res = await R.InvoicePaymentFeeListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoicePaymentFeeListRow<
  TSelect = Awaited<ReturnType<(typeof R)['InvoicePaymentFeeListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoicePaymentFeeListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoicePaymentFeeListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoicePaymentFeeListRowHook() {
  return async (...props: Parameters<typeof R.InvoicePaymentFeeListRowSpec.retrieve>) => {
    const res = await R.InvoicePaymentFeeListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListProjectListRows<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ProjectListRowSpec']['list']>>>,
>(...props: Parameters<typeof R.ProjectListRowSpec.listQ<TSelect>>) {
  return useQuery(R.ProjectListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectListRowsHook() {
  return async (...props: Parameters<typeof R.ProjectListRowSpec.list>) => {
    const res = await R.ProjectListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectListRow<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectListRowHook() {
  return async (...props: Parameters<typeof R.ProjectListRowSpec.retrieve>) => {
    const res = await R.ProjectListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListVendorListRows<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['VendorListRowSpec']['list']>>>,
>(...props: Parameters<typeof R.VendorListRowSpec.listQ<TSelect>>) {
  return useQuery(R.VendorListRowSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListVendorListRowsHook() {
  return async (...props: Parameters<typeof R.VendorListRowSpec.list>) => {
    const res = await R.VendorListRowSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveVendorListRow<
  TSelect = Awaited<ReturnType<(typeof R)['VendorListRowSpec']['retrieve']>>,
>(...props: Parameters<typeof R.VendorListRowSpec.retrieveQ<TSelect>>) {
  return useQuery(R.VendorListRowSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveVendorListRowHook() {
  return async (...props: Parameters<typeof R.VendorListRowSpec.retrieve>) => {
    const res = await R.VendorListRowSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListBillsPageSummarys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BillsPageSummarySpec']['list']>>>,
>(...props: Parameters<typeof R.BillsPageSummarySpec.listQ<TSelect>>) {
  return useQuery(R.BillsPageSummarySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillsPageSummarysHook() {
  return async (...props: Parameters<typeof R.BillsPageSummarySpec.list>) => {
    const res = await R.BillsPageSummarySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBillsPageSummary<
  TSelect = Awaited<ReturnType<(typeof R)['BillsPageSummarySpec']['retrieve']>>,
>(...props: Parameters<typeof R.BillsPageSummarySpec.retrieveQ<TSelect>>) {
  return useQuery(R.BillsPageSummarySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBillsPageSummaryHook() {
  return async (...props: Parameters<typeof R.BillsPageSummarySpec.retrieve>) => {
    const res = await R.BillsPageSummarySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListEstimatesPageSummarys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['EstimatesPageSummarySpec']['list']>>>,
>(...props: Parameters<typeof R.EstimatesPageSummarySpec.listQ<TSelect>>) {
  return useQuery(R.EstimatesPageSummarySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListEstimatesPageSummarysHook() {
  return async (...props: Parameters<typeof R.EstimatesPageSummarySpec.list>) => {
    const res = await R.EstimatesPageSummarySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveEstimatesPageSummary<
  TSelect = Awaited<ReturnType<(typeof R)['EstimatesPageSummarySpec']['retrieve']>>,
>(...props: Parameters<typeof R.EstimatesPageSummarySpec.retrieveQ<TSelect>>) {
  return useQuery(R.EstimatesPageSummarySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveEstimatesPageSummaryHook() {
  return async (...props: Parameters<typeof R.EstimatesPageSummarySpec.retrieve>) => {
    const res = await R.EstimatesPageSummarySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListInvoicesPageSummarys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['InvoicesPageSummarySpec']['list']>>>,
>(...props: Parameters<typeof R.InvoicesPageSummarySpec.listQ<TSelect>>) {
  return useQuery(R.InvoicesPageSummarySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListInvoicesPageSummarysHook() {
  return async (...props: Parameters<typeof R.InvoicesPageSummarySpec.list>) => {
    const res = await R.InvoicesPageSummarySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveInvoicesPageSummary<
  TSelect = Awaited<ReturnType<(typeof R)['InvoicesPageSummarySpec']['retrieve']>>,
>(...props: Parameters<typeof R.InvoicesPageSummarySpec.retrieveQ<TSelect>>) {
  return useQuery(R.InvoicesPageSummarySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveInvoicesPageSummaryHook() {
  return async (...props: Parameters<typeof R.InvoicesPageSummarySpec.retrieve>) => {
    const res = await R.InvoicesPageSummarySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListProjectsPageSummarys<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ProjectsPageSummarySpec']['list']>>>,
>(...props: Parameters<typeof R.ProjectsPageSummarySpec.listQ<TSelect>>) {
  return useQuery(R.ProjectsPageSummarySpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProjectsPageSummarysHook() {
  return async (...props: Parameters<typeof R.ProjectsPageSummarySpec.list>) => {
    const res = await R.ProjectsPageSummarySpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProjectsPageSummary<
  TSelect = Awaited<ReturnType<(typeof R)['ProjectsPageSummarySpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProjectsPageSummarySpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProjectsPageSummarySpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProjectsPageSummaryHook() {
  return async (...props: Parameters<typeof R.ProjectsPageSummarySpec.retrieve>) => {
    const res = await R.ProjectsPageSummarySpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useAPIActivity<TSelect = Awaited<ReturnType<(typeof R)['APIActivitySpec']['get']>>>(
  ...props: Parameters<typeof R.APIActivitySpec.getQ<TSelect>>
) {
  return useQuery(R.APIActivitySpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useAPIActivityHook() {
  return async (...props: Parameters<typeof R.APIActivitySpec.get>) => {
    const res = await R.APIActivitySpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useAggregatedPaymentListRow<
  TSelect = Awaited<ReturnType<(typeof R)['AggregatedPaymentListRowSpec']['get']>>,
>(...props: Parameters<typeof R.AggregatedPaymentListRowSpec.getQ<TSelect>>) {
  return useQuery(R.AggregatedPaymentListRowSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useAggregatedPaymentListRowHook() {
  return async (...props: Parameters<typeof R.AggregatedPaymentListRowSpec.get>) => {
    const res = await R.AggregatedPaymentListRowSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useCustomerSummary<
  TSelect = Awaited<ReturnType<(typeof R)['CustomerSummarySpec']['get']>>,
>(...props: Parameters<typeof R.CustomerSummarySpec.getQ<TSelect>>) {
  return useQuery(R.CustomerSummarySpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useCustomerSummaryHook() {
  return async (...props: Parameters<typeof R.CustomerSummarySpec.get>) => {
    const res = await R.CustomerSummarySpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetReadOnlyAuthCommand<
  TSelect = Awaited<ReturnType<(typeof R)['GetReadOnlyAuthCommandSpec']['get']>>,
>(...props: Parameters<typeof R.GetReadOnlyAuthCommandSpec.getQ<TSelect>>) {
  return useQuery(R.GetReadOnlyAuthCommandSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetReadOnlyAuthCommandHook() {
  return async (...props: Parameters<typeof R.GetReadOnlyAuthCommandSpec.get>) => {
    const res = await R.GetReadOnlyAuthCommandSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetAllowedQBBucketSearches<
  TSelect = Awaited<ReturnType<(typeof R)['GetAllowedQBBucketSearchesSpec']['get']>>,
>(...props: Parameters<typeof R.GetAllowedQBBucketSearchesSpec.getQ<TSelect>>) {
  return useQuery(R.GetAllowedQBBucketSearchesSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetAllowedQBBucketSearchesHook() {
  return async (...props: Parameters<typeof R.GetAllowedQBBucketSearchesSpec.get>) => {
    const res = await R.GetAllowedQBBucketSearchesSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListAdminQBPullRequests<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['AdminQBPullRequestSpec']['list']>>>,
>(...props: Parameters<typeof R.AdminQBPullRequestSpec.listQ<TSelect>>) {
  return useQuery(R.AdminQBPullRequestSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListAdminQBPullRequestsHook() {
  return async (...props: Parameters<typeof R.AdminQBPullRequestSpec.list>) => {
    const res = await R.AdminQBPullRequestSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveAdminQBPullRequest<
  TSelect = Awaited<ReturnType<(typeof R)['AdminQBPullRequestSpec']['retrieve']>>,
>(...props: Parameters<typeof R.AdminQBPullRequestSpec.retrieveQ<TSelect>>) {
  return useQuery(R.AdminQBPullRequestSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveAdminQBPullRequestHook() {
  return async (...props: Parameters<typeof R.AdminQBPullRequestSpec.retrieve>) => {
    const res = await R.AdminQBPullRequestSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListAdminQBPushRequests<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['AdminQBPushRequestSpec']['list']>>>,
>(...props: Parameters<typeof R.AdminQBPushRequestSpec.listQ<TSelect>>) {
  return useQuery(R.AdminQBPushRequestSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListAdminQBPushRequestsHook() {
  return async (...props: Parameters<typeof R.AdminQBPushRequestSpec.list>) => {
    const res = await R.AdminQBPushRequestSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveAdminQBPushRequest<
  TSelect = Awaited<ReturnType<(typeof R)['AdminQBPushRequestSpec']['retrieve']>>,
>(...props: Parameters<typeof R.AdminQBPushRequestSpec.retrieveQ<TSelect>>) {
  return useQuery(R.AdminQBPushRequestSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveAdminQBPushRequestHook() {
  return async (...props: Parameters<typeof R.AdminQBPushRequestSpec.retrieve>) => {
    const res = await R.AdminQBPushRequestSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListAdminTransactions<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['AdminTransactionSpec']['list']>>>,
>(...props: Parameters<typeof R.AdminTransactionSpec.listQ<TSelect>>) {
  return useQuery(R.AdminTransactionSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListAdminTransactionsHook() {
  return async (...props: Parameters<typeof R.AdminTransactionSpec.list>) => {
    const res = await R.AdminTransactionSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveAdminTransaction<
  TSelect = Awaited<ReturnType<(typeof R)['AdminTransactionSpec']['retrieve']>>,
>(...props: Parameters<typeof R.AdminTransactionSpec.retrieveQ<TSelect>>) {
  return useQuery(R.AdminTransactionSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveAdminTransactionHook() {
  return async (...props: Parameters<typeof R.AdminTransactionSpec.retrieve>) => {
    const res = await R.AdminTransactionSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListAgaveAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['AgaveAccountSpec']['list']>>>,
>(...props: Parameters<typeof R.AgaveAccountSpec.listQ<TSelect>>) {
  return useQuery(R.AgaveAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListAgaveAccountsHook() {
  return async (...props: Parameters<typeof R.AgaveAccountSpec.list>) => {
    const res = await R.AgaveAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveAgaveAccount<
  TSelect = Awaited<ReturnType<(typeof R)['AgaveAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.AgaveAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.AgaveAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveAgaveAccountHook() {
  return async (...props: Parameters<typeof R.AgaveAccountSpec.retrieve>) => {
    const res = await R.AgaveAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListBusinessNotes<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BusinessNoteSpec']['list']>>>,
>(...props: Parameters<typeof R.BusinessNoteSpec.listQ<TSelect>>) {
  return useQuery(R.BusinessNoteSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBusinessNotesHook() {
  return async (...props: Parameters<typeof R.BusinessNoteSpec.list>) => {
    const res = await R.BusinessNoteSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBusinessNote<
  TSelect = Awaited<ReturnType<(typeof R)['BusinessNoteSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BusinessNoteSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BusinessNoteSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBusinessNoteHook() {
  return async (...props: Parameters<typeof R.BusinessNoteSpec.retrieve>) => {
    const res = await R.BusinessNoteSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateBusinessNoteHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessNoteSpec.update>) => {
    const res = await R.BusinessNoteSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.BusinessNoteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useDestroyBusinessNoteHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessNoteSpec.delete>) => {
    const res = await R.BusinessNoteSpec.delete(...props)
    R.BusinessNoteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateBusinessNoteHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BusinessNoteSpec.create>) => {
    const res = await R.BusinessNoteSpec.create(...props)
    R.BusinessNoteSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useEmailPixel<TSelect = Awaited<ReturnType<(typeof R)['EmailPixelSpec']['get']>>>(
  ...props: Parameters<typeof R.EmailPixelSpec.getQ<TSelect>>
) {
  return useQuery(R.EmailPixelSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useEmailPixelHook() {
  return async (...props: Parameters<typeof R.EmailPixelSpec.get>) => {
    const res = await R.EmailPixelSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListBeamBalanceTransactions<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BeamBalanceTransactionSpec']['list']>>>,
>(...props: Parameters<typeof R.BeamBalanceTransactionSpec.listQ<TSelect>>) {
  return useQuery(R.BeamBalanceTransactionSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBeamBalanceTransactionsHook() {
  return async (...props: Parameters<typeof R.BeamBalanceTransactionSpec.list>) => {
    const res = await R.BeamBalanceTransactionSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBeamBalanceTransaction<
  TSelect = Awaited<ReturnType<(typeof R)['BeamBalanceTransactionSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BeamBalanceTransactionSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BeamBalanceTransactionSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBeamBalanceTransactionHook() {
  return async (...props: Parameters<typeof R.BeamBalanceTransactionSpec.retrieve>) => {
    const res = await R.BeamBalanceTransactionSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListTransfers<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['TransferSpec']['list']>>>,
>(...props: Parameters<typeof R.TransferSpec.listQ<TSelect>>) {
  return useQuery(R.TransferSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListTransfersHook() {
  return async (...props: Parameters<typeof R.TransferSpec.list>) => {
    const res = await R.TransferSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveTransfer<
  TSelect = Awaited<ReturnType<(typeof R)['TransferSpec']['retrieve']>>,
>(...props: Parameters<typeof R.TransferSpec.retrieveQ<TSelect>>) {
  return useQuery(R.TransferSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveTransferHook() {
  return async (...props: Parameters<typeof R.TransferSpec.retrieve>) => {
    const res = await R.TransferSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateTransferHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.TransferSpec.create>) => {
    const res = await R.TransferSpec.create(...props)
    R.TransferSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetHubspotVisitorToken<
  TSelect = Awaited<ReturnType<(typeof R)['GetHubspotVisitorTokenSpec']['get']>>,
>(...props: Parameters<typeof R.GetHubspotVisitorTokenSpec.getQ<TSelect>>) {
  return useQuery(R.GetHubspotVisitorTokenSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetHubspotVisitorTokenHook() {
  return async (...props: Parameters<typeof R.GetHubspotVisitorTokenSpec.get>) => {
    const res = await R.GetHubspotVisitorTokenSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListCardTransactions<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CardTransactionSpec']['list']>>>,
>(...props: Parameters<typeof R.CardTransactionSpec.listQ<TSelect>>) {
  return useQuery(R.CardTransactionSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCardTransactionsHook() {
  return async (...props: Parameters<typeof R.CardTransactionSpec.list>) => {
    const res = await R.CardTransactionSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCardTransaction<
  TSelect = Awaited<ReturnType<(typeof R)['CardTransactionSpec']['retrieve']>>,
>(...props: Parameters<typeof R.CardTransactionSpec.retrieveQ<TSelect>>) {
  return useQuery(R.CardTransactionSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCardTransactionHook() {
  return async (...props: Parameters<typeof R.CardTransactionSpec.retrieve>) => {
    const res = await R.CardTransactionSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListExpenseCards<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ExpenseCardSpec']['list']>>>,
>(...props: Parameters<typeof R.ExpenseCardSpec.listQ<TSelect>>) {
  return useQuery(R.ExpenseCardSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListExpenseCardsHook() {
  return async (...props: Parameters<typeof R.ExpenseCardSpec.list>) => {
    const res = await R.ExpenseCardSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveExpenseCard<
  TSelect = Awaited<ReturnType<(typeof R)['ExpenseCardSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ExpenseCardSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ExpenseCardSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveExpenseCardHook() {
  return async (...props: Parameters<typeof R.ExpenseCardSpec.retrieve>) => {
    const res = await R.ExpenseCardSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListCardPrograms<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CardProgramSpec']['list']>>>,
>(...props: Parameters<typeof R.CardProgramSpec.listQ<TSelect>>) {
  return useQuery(R.CardProgramSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCardProgramsHook() {
  return async (...props: Parameters<typeof R.CardProgramSpec.list>) => {
    const res = await R.CardProgramSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCardProgram<
  TSelect = Awaited<ReturnType<(typeof R)['CardProgramSpec']['retrieve']>>,
>(...props: Parameters<typeof R.CardProgramSpec.retrieveQ<TSelect>>) {
  return useQuery(R.CardProgramSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCardProgramHook() {
  return async (...props: Parameters<typeof R.CardProgramSpec.retrieve>) => {
    const res = await R.CardProgramSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useGetAccountLoginURL<
  TSelect = Awaited<ReturnType<(typeof R)['GetAccountLoginURLSpec']['get']>>,
>(...props: Parameters<typeof R.GetAccountLoginURLSpec.getQ<TSelect>>) {
  return useQuery(R.GetAccountLoginURLSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetAccountLoginURLHook() {
  return async (...props: Parameters<typeof R.GetAccountLoginURLSpec.get>) => {
    const res = await R.GetAccountLoginURLSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetInvoiceCta<
  TSelect = Awaited<ReturnType<(typeof R)['GetInvoiceCtaSpec']['get']>>,
>(...props: Parameters<typeof R.GetInvoiceCtaSpec.getQ<TSelect>>) {
  return useQuery(R.GetInvoiceCtaSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetInvoiceCtaHook() {
  return async (...props: Parameters<typeof R.GetInvoiceCtaSpec.get>) => {
    const res = await R.GetInvoiceCtaSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useGetInvoiceListCta<
  TSelect = Awaited<ReturnType<(typeof R)['GetInvoiceListCtaSpec']['get']>>,
>(...props: Parameters<typeof R.GetInvoiceListCtaSpec.getQ<TSelect>>) {
  return useQuery(R.GetInvoiceListCtaSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetInvoiceListCtaHook() {
  return async (...props: Parameters<typeof R.GetInvoiceListCtaSpec.get>) => {
    const res = await R.GetInvoiceListCtaSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListTutorials<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['TutorialSpec']['list']>>>,
>(...props: Parameters<typeof R.TutorialSpec.listQ<TSelect>>) {
  return useQuery(R.TutorialSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListTutorialsHook() {
  return async (...props: Parameters<typeof R.TutorialSpec.list>) => {
    const res = await R.TutorialSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveTutorial<
  TSelect = Awaited<ReturnType<(typeof R)['TutorialSpec']['retrieve']>>,
>(...props: Parameters<typeof R.TutorialSpec.retrieveQ<TSelect>>) {
  return useQuery(R.TutorialSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveTutorialHook() {
  return async (...props: Parameters<typeof R.TutorialSpec.retrieve>) => {
    const res = await R.TutorialSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateTutorialHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.TutorialSpec.update>) => {
    const res = await R.TutorialSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.TutorialSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useListExpenseCodes<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ExpenseCodeSpec']['list']>>>,
>(...props: Parameters<typeof R.ExpenseCodeSpec.listQ<TSelect>>) {
  return useQuery(R.ExpenseCodeSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListExpenseCodesHook() {
  return async (...props: Parameters<typeof R.ExpenseCodeSpec.list>) => {
    const res = await R.ExpenseCodeSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveExpenseCode<
  TSelect = Awaited<ReturnType<(typeof R)['ExpenseCodeSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ExpenseCodeSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ExpenseCodeSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveExpenseCodeHook() {
  return async (...props: Parameters<typeof R.ExpenseCodeSpec.retrieve>) => {
    const res = await R.ExpenseCodeSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListIncomeCodes<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['IncomeCodeSpec']['list']>>>,
>(...props: Parameters<typeof R.IncomeCodeSpec.listQ<TSelect>>) {
  return useQuery(R.IncomeCodeSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListIncomeCodesHook() {
  return async (...props: Parameters<typeof R.IncomeCodeSpec.list>) => {
    const res = await R.IncomeCodeSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveIncomeCode<
  TSelect = Awaited<ReturnType<(typeof R)['IncomeCodeSpec']['retrieve']>>,
>(...props: Parameters<typeof R.IncomeCodeSpec.retrieveQ<TSelect>>) {
  return useQuery(R.IncomeCodeSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveIncomeCodeHook() {
  return async (...props: Parameters<typeof R.IncomeCodeSpec.retrieve>) => {
    const res = await R.IncomeCodeSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListItems<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ItemSpec']['list']>>>,
>(...props: Parameters<typeof R.ItemSpec.listQ<TSelect>>) {
  return useQuery(R.ItemSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListItemsHook() {
  return async (...props: Parameters<typeof R.ItemSpec.list>) => {
    const res = await R.ItemSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveItem<TSelect = Awaited<ReturnType<(typeof R)['ItemSpec']['retrieve']>>>(
  ...props: Parameters<typeof R.ItemSpec.retrieveQ<TSelect>>
) {
  return useQuery(R.ItemSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveItemHook() {
  return async (...props: Parameters<typeof R.ItemSpec.retrieve>) => {
    const res = await R.ItemSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListLedgerAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['LedgerAccountSpec']['list']>>>,
>(...props: Parameters<typeof R.LedgerAccountSpec.listQ<TSelect>>) {
  return useQuery(R.LedgerAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListLedgerAccountsHook() {
  return async (...props: Parameters<typeof R.LedgerAccountSpec.list>) => {
    const res = await R.LedgerAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveLedgerAccount<
  TSelect = Awaited<ReturnType<(typeof R)['LedgerAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.LedgerAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.LedgerAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveLedgerAccountHook() {
  return async (...props: Parameters<typeof R.LedgerAccountSpec.retrieve>) => {
    const res = await R.LedgerAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListBillOcrRawResultLineItems<
  TSelect = ExtractFromList<
    Awaited<ReturnType<(typeof R)['BillOcrRawResultLineItemSpec']['list']>>
  >,
>(...props: Parameters<typeof R.BillOcrRawResultLineItemSpec.listQ<TSelect>>) {
  return useQuery(R.BillOcrRawResultLineItemSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillOcrRawResultLineItemsHook() {
  return async (...props: Parameters<typeof R.BillOcrRawResultLineItemSpec.list>) => {
    const res = await R.BillOcrRawResultLineItemSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBillOcrRawResultLineItem<
  TSelect = Awaited<ReturnType<(typeof R)['BillOcrRawResultLineItemSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BillOcrRawResultLineItemSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BillOcrRawResultLineItemSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBillOcrRawResultLineItemHook() {
  return async (...props: Parameters<typeof R.BillOcrRawResultLineItemSpec.retrieve>) => {
    const res = await R.BillOcrRawResultLineItemSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListBillOcrRawResults<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['BillOcrRawResultSpec']['list']>>>,
>(...props: Parameters<typeof R.BillOcrRawResultSpec.listQ<TSelect>>) {
  return useQuery(R.BillOcrRawResultSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListBillOcrRawResultsHook() {
  return async (...props: Parameters<typeof R.BillOcrRawResultSpec.list>) => {
    const res = await R.BillOcrRawResultSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveBillOcrRawResult<
  TSelect = Awaited<ReturnType<(typeof R)['BillOcrRawResultSpec']['retrieve']>>,
>(...props: Parameters<typeof R.BillOcrRawResultSpec.retrieveQ<TSelect>>) {
  return useQuery(R.BillOcrRawResultSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveBillOcrRawResultHook() {
  return async (...props: Parameters<typeof R.BillOcrRawResultSpec.retrieve>) => {
    const res = await R.BillOcrRawResultSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListMfaRequirements<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['MfaRequirementSpec']['list']>>>,
>(...props: Parameters<typeof R.MfaRequirementSpec.listQ<TSelect>>) {
  return useQuery(R.MfaRequirementSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListMfaRequirementsHook() {
  return async (...props: Parameters<typeof R.MfaRequirementSpec.list>) => {
    const res = await R.MfaRequirementSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveMfaRequirement<
  TSelect = Awaited<ReturnType<(typeof R)['MfaRequirementSpec']['retrieve']>>,
>(...props: Parameters<typeof R.MfaRequirementSpec.retrieveQ<TSelect>>) {
  return useQuery(R.MfaRequirementSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveMfaRequirementHook() {
  return async (...props: Parameters<typeof R.MfaRequirementSpec.retrieve>) => {
    const res = await R.MfaRequirementSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useGetAuthRequestLink<
  TSelect = Awaited<ReturnType<(typeof R)['GetAuthRequestLinkSpec']['get']>>,
>(...props: Parameters<typeof R.GetAuthRequestLinkSpec.getQ<TSelect>>) {
  return useQuery(R.GetAuthRequestLinkSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetAuthRequestLinkHook() {
  return async (...props: Parameters<typeof R.GetAuthRequestLinkSpec.get>) => {
    const res = await R.GetAuthRequestLinkSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListCompanyCamAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CompanyCamAccountSpec']['list']>>>,
>(...props: Parameters<typeof R.CompanyCamAccountSpec.listQ<TSelect>>) {
  return useQuery(R.CompanyCamAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCompanyCamAccountsHook() {
  return async (...props: Parameters<typeof R.CompanyCamAccountSpec.list>) => {
    const res = await R.CompanyCamAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCompanyCamAccount<
  TSelect = Awaited<ReturnType<(typeof R)['CompanyCamAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.CompanyCamAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.CompanyCamAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCompanyCamAccountHook() {
  return async (...props: Parameters<typeof R.CompanyCamAccountSpec.retrieve>) => {
    const res = await R.CompanyCamAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateCompanyCamAccountHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.CompanyCamAccountSpec.create>) => {
    const res = await R.CompanyCamAccountSpec.create(...props)
    R.CompanyCamAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonCompanyCamAccount<
  TSelect = Awaited<ReturnType<(typeof R)['CompanyCamAccountSpec']['singleton']>>,
>(...props: Parameters<typeof R.CompanyCamAccountSpec.singletonQ<TSelect>>) {
  return useQuery(R.CompanyCamAccountSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListProcoreAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['ProcoreAccountSpec']['list']>>>,
>(...props: Parameters<typeof R.ProcoreAccountSpec.listQ<TSelect>>) {
  return useQuery(R.ProcoreAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListProcoreAccountsHook() {
  return async (...props: Parameters<typeof R.ProcoreAccountSpec.list>) => {
    const res = await R.ProcoreAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveProcoreAccount<
  TSelect = Awaited<ReturnType<(typeof R)['ProcoreAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.ProcoreAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.ProcoreAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveProcoreAccountHook() {
  return async (...props: Parameters<typeof R.ProcoreAccountSpec.retrieve>) => {
    const res = await R.ProcoreAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateProcoreAccountHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ProcoreAccountSpec.create>) => {
    const res = await R.ProcoreAccountSpec.create(...props)
    R.ProcoreAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonProcoreAccount<
  TSelect = Awaited<ReturnType<(typeof R)['ProcoreAccountSpec']['singleton']>>,
>(...props: Parameters<typeof R.ProcoreAccountSpec.singletonQ<TSelect>>) {
  return useQuery(R.ProcoreAccountSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListQboAccounts<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['QboAccountSpec']['list']>>>,
>(...props: Parameters<typeof R.QboAccountSpec.listQ<TSelect>>) {
  return useQuery(R.QboAccountSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListQboAccountsHook() {
  return async (...props: Parameters<typeof R.QboAccountSpec.list>) => {
    const res = await R.QboAccountSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveQboAccount<
  TSelect = Awaited<ReturnType<(typeof R)['QboAccountSpec']['retrieve']>>,
>(...props: Parameters<typeof R.QboAccountSpec.retrieveQ<TSelect>>) {
  return useQuery(R.QboAccountSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveQboAccountHook() {
  return async (...props: Parameters<typeof R.QboAccountSpec.retrieve>) => {
    const res = await R.QboAccountSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useCreateQboAccountHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.QboAccountSpec.create>) => {
    const res = await R.QboAccountSpec.create(...props)
    R.QboAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useRetrieveSingletonQboAccount<
  TSelect = Awaited<ReturnType<(typeof R)['QboAccountSpec']['singleton']>>,
>(...props: Parameters<typeof R.QboAccountSpec.singletonQ<TSelect>>) {
  return useQuery(R.QboAccountSpec.singletonQ<TSelect>(...props))
}

// ~~~~~~

export function useListCompanyCamLinks<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['CompanyCamLinkSpec']['list']>>>,
>(...props: Parameters<typeof R.CompanyCamLinkSpec.listQ<TSelect>>) {
  return useQuery(R.CompanyCamLinkSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListCompanyCamLinksHook() {
  return async (...props: Parameters<typeof R.CompanyCamLinkSpec.list>) => {
    const res = await R.CompanyCamLinkSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveCompanyCamLink<
  TSelect = Awaited<ReturnType<(typeof R)['CompanyCamLinkSpec']['retrieve']>>,
>(...props: Parameters<typeof R.CompanyCamLinkSpec.retrieveQ<TSelect>>) {
  return useQuery(R.CompanyCamLinkSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveCompanyCamLinkHook() {
  return async (...props: Parameters<typeof R.CompanyCamLinkSpec.retrieve>) => {
    const res = await R.CompanyCamLinkSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListDocuments<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['DocumentSpec']['list']>>>,
>(...props: Parameters<typeof R.DocumentSpec.listQ<TSelect>>) {
  return useQuery(R.DocumentSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListDocumentsHook() {
  return async (...props: Parameters<typeof R.DocumentSpec.list>) => {
    const res = await R.DocumentSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveDocument<
  TSelect = Awaited<ReturnType<(typeof R)['DocumentSpec']['retrieve']>>,
>(...props: Parameters<typeof R.DocumentSpec.retrieveQ<TSelect>>) {
  return useQuery(R.DocumentSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveDocumentHook() {
  return async (...props: Parameters<typeof R.DocumentSpec.retrieve>) => {
    const res = await R.DocumentSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListPhotos<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['PhotoSpec']['list']>>>,
>(...props: Parameters<typeof R.PhotoSpec.listQ<TSelect>>) {
  return useQuery(R.PhotoSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListPhotosHook() {
  return async (...props: Parameters<typeof R.PhotoSpec.list>) => {
    const res = await R.PhotoSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrievePhoto<
  TSelect = Awaited<ReturnType<(typeof R)['PhotoSpec']['retrieve']>>,
>(...props: Parameters<typeof R.PhotoSpec.retrieveQ<TSelect>>) {
  return useQuery(R.PhotoSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrievePhotoHook() {
  return async (...props: Parameters<typeof R.PhotoSpec.retrieve>) => {
    const res = await R.PhotoSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useListRenofiAds<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['RenofiAdSpec']['list']>>>,
>(...props: Parameters<typeof R.RenofiAdSpec.listQ<TSelect>>) {
  return useQuery(R.RenofiAdSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListRenofiAdsHook() {
  return async (...props: Parameters<typeof R.RenofiAdSpec.list>) => {
    const res = await R.RenofiAdSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveRenofiAd<
  TSelect = Awaited<ReturnType<(typeof R)['RenofiAdSpec']['retrieve']>>,
>(...props: Parameters<typeof R.RenofiAdSpec.retrieveQ<TSelect>>) {
  return useQuery(R.RenofiAdSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveRenofiAdHook() {
  return async (...props: Parameters<typeof R.RenofiAdSpec.retrieve>) => {
    const res = await R.RenofiAdSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function usePartialUpdateRenofiAdHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.RenofiAdSpec.update>) => {
    const res = await R.RenofiAdSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.RenofiAdSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateRenofiAdHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.RenofiAdSpec.create>) => {
    const res = await R.RenofiAdSpec.create(...props)
    R.RenofiAdSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetRenofiAd<TSelect = Awaited<ReturnType<(typeof R)['GetRenofiAdSpec']['get']>>>(
  ...props: Parameters<typeof R.GetRenofiAdSpec.getQ<TSelect>>
) {
  return useQuery(R.GetRenofiAdSpec.getQ<TSelect>(...props))
}

// ~~~~~~

export function useGetRenofiAdHook() {
  return async (...props: Parameters<typeof R.GetRenofiAdSpec.get>) => {
    const res = await R.GetRenofiAdSpec.get(...props)
    return res
  }
}

// ~~~~~~

export function useListRenofiAdClicks<
  TSelect = ExtractFromList<Awaited<ReturnType<(typeof R)['RenofiAdClickSpec']['list']>>>,
>(...props: Parameters<typeof R.RenofiAdClickSpec.listQ<TSelect>>) {
  return useQuery(R.RenofiAdClickSpec.listQ<TSelect>(...props))
}

// ~~~~~~

export function useListRenofiAdClicksHook() {
  return async (...props: Parameters<typeof R.RenofiAdClickSpec.list>) => {
    const res = await R.RenofiAdClickSpec.list(...props)
    return res
  }
}

// ~~~~~~

export function useRetrieveRenofiAdClick<
  TSelect = Awaited<ReturnType<(typeof R)['RenofiAdClickSpec']['retrieve']>>,
>(...props: Parameters<typeof R.RenofiAdClickSpec.retrieveQ<TSelect>>) {
  return useQuery(R.RenofiAdClickSpec.retrieveQ<TSelect>(...props))
}

// ~~~~~~

export function useRetrieveRenofiAdClickHook() {
  return async (...props: Parameters<typeof R.RenofiAdClickSpec.retrieve>) => {
    const res = await R.RenofiAdClickSpec.retrieve(...props)
    return res
  }
}

// ~~~~~~

export function useSetBusinessStatusHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SetBusinessStatusSpec.post>) => {
    const res = await R.SetBusinessStatusSpec.post(...props)
    R.SetBusinessStatusSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useBulkCreateInvoicePaymentHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BulkCreateInvoicePaymentSpec.post>) => {
    const res = await R.BulkCreateInvoicePaymentSpec.post(...props)
    R.BulkCreateInvoicePaymentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useBumpInvoiceVersionHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.BumpInvoiceVersionSpec.post>) => {
    const res = await R.BumpInvoiceVersionSpec.post(...props)
    R.BumpInvoiceVersionSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useSendInvoiceEmailHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SendInvoiceEmailSpec.post>) => {
    const res = await R.SendInvoiceEmailSpec.post(...props)
    R.SendInvoiceEmailSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useSendLienWaiverEmailHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SendLienWaiverEmailSpec.post>) => {
    const res = await R.SendLienWaiverEmailSpec.post(...props)
    R.SendLienWaiverEmailSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateLoginHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.LoginSpec.create>) => {
    const res = await R.LoginSpec.create(...props)
    R.LoginSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateLogoutV2Hook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.LogoutV2Spec.create>) => {
    const res = await R.LogoutV2Spec.create(...props)
    R.LogoutV2Spec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useAuthenticateMagicLinkHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.AuthenticateMagicLinkSpec.post>) => {
    const res = await R.AuthenticateMagicLinkSpec.post(...props)
    R.AuthenticateMagicLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGenerateMagicLinkTokenHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.GenerateMagicLinkTokenSpec.post>) => {
    const res = await R.GenerateMagicLinkTokenSpec.post(...props)
    R.GenerateMagicLinkTokenSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function usePasswordResetCompletionHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PasswordResetCompletionSpec.post>) => {
    const res = await R.PasswordResetCompletionSpec.post(...props)
    R.PasswordResetCompletionSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function usePasswordResetInitializationHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PasswordResetInitializationSpec.post>) => {
    const res = await R.PasswordResetInitializationSpec.post(...props)
    R.PasswordResetInitializationSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useVerifyEmailHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.VerifyEmailSpec.post>) => {
    const res = await R.VerifyEmailSpec.post(...props)
    R.VerifyEmailSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateMicrodepositAttemptHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.MicrodepositAttemptSpec.create>) => {
    const res = await R.MicrodepositAttemptSpec.create(...props)
    R.MicrodepositAttemptSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function usePartialUpdateSetupIntentHook(options?: { disableAutoInvalidate?: boolean }) {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SetupIntentSpec.update>) => {
    const res = await R.SetupIntentSpec.update(...props)
    if (!options?.disableAutoInvalidate) {
      R.SetupIntentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    }
    return res
  }
}

// ~~~~~~

export function useCreateSetupIntentHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SetupIntentSpec.create>) => {
    const res = await R.SetupIntentSpec.create(...props)
    R.SetupIntentSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useSetInboundTransfersEnabledHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SetInboundTransfersEnabledSpec.post>) => {
    const res = await R.SetInboundTransfersEnabledSpec.post(...props)
    R.SetInboundTransfersEnabledSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useSetMfaRequirementHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SetMfaRequirementSpec.post>) => {
    const res = await R.SetMfaRequirementSpec.post(...props)
    R.SetMfaRequirementSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateFileUploadHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.FileUploadSpec.create>) => {
    const res = await R.FileUploadSpec.create(...props)
    R.FileUploadSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGenerateStripeOnboardingClientSecretHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.GenerateStripeOnboardingClientSecretSpec.post>) => {
    const res = await R.GenerateStripeOnboardingClientSecretSpec.post(...props)
    R.GenerateStripeOnboardingClientSecretSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useSetOnboardingPushedToStripeHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SetOnboardingPushedToStripeSpec.post>) => {
    const res = await R.SetOnboardingPushedToStripeSpec.post(...props)
    R.SetOnboardingPushedToStripeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useSetPayerEnabledHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SetPayerEnabledSpec.post>) => {
    const res = await R.SetPayerEnabledSpec.post(...props)
    R.SetPayerEnabledSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useSendClientInvoiceReceivedEmailViewSetHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SendClientInvoiceReceivedEmailViewSetSpec.post>) => {
    const res = await R.SendClientInvoiceReceivedEmailViewSetSpec.post(...props)
    R.SendClientInvoiceReceivedEmailViewSetSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function usePublishEstimateHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.PublishEstimateSpec.post>) => {
    const res = await R.PublishEstimateSpec.post(...props)
    R.PublishEstimateSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useSendEstimateReminderHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.SendEstimateReminderSpec.post>) => {
    const res = await R.SendEstimateReminderSpec.post(...props)
    R.SendEstimateReminderSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useUploadEstimateTemplateHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.UploadEstimateTemplateSpec.post>) => {
    const res = await R.UploadEstimateTemplateSpec.post(...props)
    R.UploadEstimateTemplateSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useArchiveBankAccountHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ArchiveBankAccountSpec.post>) => {
    const res = await R.ArchiveBankAccountSpec.post(...props)
    R.ArchiveBankAccountSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useGetQBBucketsHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.GetQBBucketsSpec.post>) => {
    const res = await R.GetQBBucketsSpec.post(...props)
    R.GetQBBucketsSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useTrackEmailLinkClickedHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.TrackEmailLinkClickedSpec.post>) => {
    const res = await R.TrackEmailLinkClickedSpec.post(...props)
    R.TrackEmailLinkClickedSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCategorizeTransactionHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.CategorizeTransactionSpec.post>) => {
    const res = await R.CategorizeTransactionSpec.post(...props)
    R.CategorizeTransactionSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useInitiateBillOcrHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.InitiateBillOcrSpec.post>) => {
    const res = await R.InitiateBillOcrSpec.post(...props)
    R.InitiateBillOcrSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateMfaAttemptHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.MfaAttemptSpec.create>) => {
    const res = await R.MfaAttemptSpec.create(...props)
    R.MfaAttemptSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useCreateESignatureHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ESignatureSpec.create>) => {
    const res = await R.ESignatureSpec.create(...props)
    R.ESignatureSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useUpdateAuthTokenHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.UpdateAuthTokenSpec.post>) => {
    const res = await R.UpdateAuthTokenSpec.post(...props)
    R.UpdateAuthTokenSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useClickLinkHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.ClickLinkSpec.post>) => {
    const res = await R.ClickLinkSpec.post(...props)
    R.ClickLinkSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useOnboardBusinessToStripeHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.OnboardBusinessToStripeSpec.post>) => {
    const res = await R.OnboardBusinessToStripeSpec.post(...props)
    R.OnboardBusinessToStripeSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}

// ~~~~~~

export function useUpdateMoneyMovementHook() {
  const qc = useQueryClient()
  return async (...props: Parameters<typeof R.UpdateMoneyMovementSpec.post>) => {
    const res = await R.UpdateMoneyMovementSpec.post(...props)
    R.UpdateMoneyMovementSpec.downstream.map((x) => qc.invalidateQueries([x]))
    return res
  }
}
